import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Helmet } from 'react-helmet-async';
import * as yup from "yup";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  createTheme,
  styled,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
import { useParams } from "react-router-dom";
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const ContactDetails= () => {
  const {contactid } = useParams();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  console.log(userid, username, "-------------------------")
  const [contactOwners, setContactOwners] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const [contactImage, setContactImage] = useState(null);
  const initialValues = {
    firstname: userid || "",
    leadsource: "",
    name: "",
    lastname: "",
    accountname: "",
    title: "",
    email: "",
    department: "",
    phone: "",
    homephone: "",
    otherphone: "",
    fax: "",
    mobile: "",
    dateOfbirth: "",
    assistant: "",
    asstphone: "",
    emailoptout: false,
    skypeid: "",
    secondaryemail: "",
    twitter: "",
    reportingto: "",
    mailingstreet: "",
    otherstreet: "",
    mailingcity: "",
    othercity: "",
    mailingstate: "",
    otherstate: "",
    mailingzip: "",
    otherzip: "",
    mailingcountry: "",
    othercountry: "",
    description: "",
    companycodes: "", // Initialize company code
  }
  console.log(initialValues)
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || ""); // New state for selected company code
  const [companycodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
    const fetchLeadData = async () => {
      try {
        const response = await axios.get(`/getcontactbyid/${contactid}`);
       console.log(response.data)
       const contactdata = response.data;
        if (response && response.data) {
          setValues({
            ...contactdata,
          });
          console.log(response.data)
        } else {
          // Handle error for failed request
        }
      } catch (error) {
        // Handle network errors
      }
    };
 
  useEffect(() => {
    fetchLeadData();
    setLeadSources(["Web", "Phone", "Email", "Other"]);
  }, [userrole, companycode, userid, username,contactid]);
  // Watch for changes in selectedCompanyCode and fetch data accordingly
  const validationSchema = yup.object({
    firstname: yup.string().required("Contact Owner is required"),
    name: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup.string().required("Phone is required"),
  });
  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
  });
  return (
    <>
      <Helmet>
        <title>Create Contact - Contacttab.com</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h1>Create Contact</h1>

          <h4>Contact Image</h4>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          </Box>

          <h4 style={{ margin: '20px 0px' }}>Contact Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="firstname" style={{ marginBottom: '4px' }}>
                  Contact Owner
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                <CustomTextField
                  fullWidth
                  id="firstname"
                  name="firstname"
                  value={values.firstname}
                />
                </FormControl>
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="leadsource" style={{ marginBottom: '4px' }}>
                  Lead Source
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <CustomTextField
                    name="leadsource"
                    value={values.leadsource}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="name" style={{ marginBottom: '4px' }}>
                  First Name
                </InputLabel>
              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={1.6}>
                <InputLabel htmlFor="lastname" style={{ marginBottom: '4px' }}>
                  Last Name
                </InputLabel>
              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  id="lastname"
                  name="lastname"
                  value={values.lastname}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="accountname" style={{ marginBottom: '4px' }}>
                  Account Name
                </InputLabel>
              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="accountname"
                  value={values.accountname}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="title" style={{ marginBottom: '4px' }}>
                  Title
                </InputLabel>
              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="title"
                  value={values.title}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="email" style={{ marginBottom: '4px' }}>
                  Email
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="email"
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="department" style={{ marginBottom: '4px' }}>
                  Department
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="department"
                  value={values.department}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Phone Number Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="phone" style={{ marginBottom: '4px' }}>
                  Phone No
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="phone"
                  value={values.phone}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>

              {/* Company Code Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="companycodes" style={{ marginBottom: '4px' }}>
                  Company Code
                </InputLabel>
              </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="text"
                    value={values.companycodes}
                    name="companycodes"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              {/* Home Phone Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="homephone" style={{ marginBottom: '4px' }}>
                  Home Phone
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="homephone"
                  value={values.homephone}
                />
              </Grid>

              {/* Other Phone Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="otherphone" style={{ marginBottom: '4px' }}>
                  Other Phone
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="otherphone"
                  value={values.otherphone}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Fax Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="fax" style={{ marginBottom: '4px' }}>
                  Fax
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="fax"
                  value={values.fax}
                />
              </Grid>

              {/* Mobile Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="mobile" style={{ marginBottom: '4px' }}>
                  Mobile
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="mobile"
                  value={values.mobile}
                />
              </Grid></Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Date of Birth Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="dateOfbirth" style={{ marginBottom: '4px' }}>
                  Date of Birth
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="dateOfbirth"
                  type="date"
                  value={values.dateOfbirth}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Assistant Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="assistant" style={{ marginBottom: '4px' }}>
                  Assistant
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="assistant"
                  value={values.assistant}
                />
              </Grid></Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Assistant Phone Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="asstphone" style={{ marginBottom: '4px' }}>
                  Assistant Phone
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="asstphone"
                  value={values.asstphone}
                />
              </Grid>

              {/* Skype ID Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="skypeid" style={{ marginBottom: '4px' }}>
                  Skype ID
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="skypeid"
                  value={values.skypeid}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Secondary Email Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="secondaryemail" style={{ marginBottom: '4px' }}>
                  Secondary Email
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="secondaryemail"
                  value={values.secondaryemail}
                />
              </Grid>

              {/* Twitter Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="twitter" style={{ marginBottom: '4px' }}>
                  Twitter
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="twitter"
                  value={values.twitter}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              {/* Reporting To Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="reportingto" style={{ marginBottom: '4px' }}>
                  Reporting To
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="reportingto"
                  value={values.reportingto}
                />
              </Grid>
            </Grid>

          </Grid>

          <h4 style={{ margin: '20px 20px' }}>Address Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="mailingstreet" style={{ marginBottom: '4px' }}>
                  Mailing Street
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="mailingstreet"

                  value={values.mailingstreet}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="otherstreet" style={{ marginBottom: '4px' }}>
                  Other Street
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="otherstreet"

                  value={values.otherstreet}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="mailingcity" style={{ marginBottom: '4px' }}>
                  Mailing City
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="mailingcity"

                  value={values.mailingcity}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="othercity" style={{ marginBottom: '4px' }}>
                  Other City
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="othercity"

                  value={values.othercity}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="mailingstate" style={{ marginBottom: '4px' }}>
                  Mailing State
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="mailingstate"

                  value={values.mailingstate}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="otherstate" style={{ marginBottom: '4px' }}>
                  Other State
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="otherstate"

                  value={values.otherstate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="mailingzip" style={{ marginBottom: '4px' }}>
                  Mailing Zip
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="mailingzip"

                  value={values.mailingzip}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="otherzip" style={{ marginBottom: '4px' }}>
                  Other Zip
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="otherzip"

                  value={values.otherzip}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="mailingcountry" style={{ marginBottom: '4px' }}>
                  Mailing Country
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="mailingcountry"

                  value={values.mailingcountry}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="othercountry" style={{ marginBottom: '4px' }}>
                  Other Country
                </InputLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="othercountry"

                  value={values.othercountry}
                />
              </Grid>
            </Grid>
          </Grid>

          <h4 style={{ margin: '20px 20px' }}>Additional Information</h4>
          <Grid container spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="description" style={{ marginBottom: '4px' }}>
                  Description
                </InputLabel>
              </Grid>
              <Grid item xs={10}>
                <CustomTextField
                  fullWidth
                  name="description"

                  multiline
                  rows={4}
                  value={values.description}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
      <br />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default ContactDetails;
