import React, { useState, useEffect } from "react";
import { Autocomplete, styled, Box, Typography, IconButton, InputLabel, FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { useUser } from "../../contexts/auth";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { useFormik } from "formik";
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));
const AllMeeting = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const { userrole, companycode, userid, username } = useUser();
  const initialValues = {
    title: "",
    location: "",
    from: null,
    to: null,
    allDay: false,
    host: userid || "",
    participants: "",
    relatedTo: "",
    repeat: "None",
    description: "",
    companyCodes: companycode || "",
  };
  const [companyCodes, setCompanyCodes] = useState([]);
  const [selectedView, setSelectedView] = useState('All Meetings');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSystemFilters, setSelectedSystemFilters] = useState([]);
  const [nameFilter, setNameFilter] = useState('All');
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]); // To store selected emails
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [contactOwners, setContactOwners] = useState([]);
  const [selectedFieldFilters, setSelectedFieldFilters] = useState([]);
  const [fieldSearchTerms, setFieldSearchTerms] = useState({});
  const [meeting, setMeeting] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState(meeting);
  const [openLeadsDialog, setOpenLeadsDialog] = useState(false);
  const [filterType, setFilterType] = useState('Leads'); // Default filter set to Leads
  const [searchQuery, setSearchQuery] = useState('');
  const [showLeadsWithoutEmail, setShowLeadsWithoutEmail] = useState(true);
  const [inviteEmails, setInviteEmails] = useState('');
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || "");
  const [leads, setLeads] = useState([]);
  const [contact, setContact] = useState([]);
  const [user, setUser] = useState([]);
  // Define state variables for each category
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // Add this
  const [selectedContacts, setSelectedContacts] = useState([]); // Add this
 
  const handleFieldFilterChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedFieldFilters((prev) => [...prev, name]);
      setFieldSearchTerms((prev) => ({ ...prev, [name]: "" }));
    } else {
      setSelectedFieldFilters((prev) => prev.filter((filter) => filter !== name));
      setFieldSearchTerms((prev) => {
        const { [name]: removed, ...rest } = prev;
        return rest;
      });
    }
  };
  const handleFieldSearchChange = (event, filterName) => {
    console.log("----------------")
    setFieldSearchTerms((prev) => ({
      ...prev,
      [filterName]: event.target.value
    }));
  };
  const handleCreateMeeting = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setValues({
      title: "",
      location: "",
      from: null,
      to: null,
      allDay: false,
      host: "",
      participants: "",
      relatedTo: "",
      repeat: "None",
      description:"",
      companyCodes: "",
    });
  };
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setValues(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'allDay') {
      if (checked) {
        setValues(prev => ({
          ...prev,
          from: prev.from ? new Date(prev.from.setHours(0, 0, 0, 0)) : null,
          to: prev.to ? new Date(prev.to.setHours(23, 59, 59, 999)) : null,
        }));
      } else {
        const now = new Date();
        const nextHour = new Date(now.setHours(now.getHours() + 1, 0, 0, 0));
        const oneHourLater = new Date(nextHour.getTime() + 60 * 60 * 1000);
        setValues(prev => ({
          ...prev,
          from: nextHour,
          to: oneHourLater,
        }));
      }
    }
  };
  const handleDateChange = (name, date) => {
    setValues(prev => ({
      ...prev,
      [name]: date,
    }));
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    setValues
  } = useFormik({
    initialValues,
    // validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const formattedFrom = new Date(values.from).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });

        const formattedTo = new Date(values.to).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });

        const leadDataWithUsername = {
          ...values,
          from: formattedFrom,
          to: formattedTo,
          username: username,
        };
        const formDataToSend = new FormData();
        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        formDataToSend.append("emails", JSON.stringify(selectedParticipants));
        console.log(JSON.stringify(leadDataWithUsername))
        console.log(JSON.stringify(selectedParticipants))
        const response = await axios.post("/addmeeting", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("Lead data added successful");
          setOpenSuccess(true);
          // sendMsg();
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("Lead data not added");
          }, 3000);
          handleCloseCreateDialog();
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const fetchDatas = async () => {
    try {
      const response = await axios.get("/getCompanyRegistrations");
      console.log(response.data);
      const formattedCodes = response.data.map(
        (company) => company.establishmentid
      );
      setCompanyCodes(formattedCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchleadData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getLeads", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setLeads(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchData = async () => {
    try {
      if (selectedCompanyCode) {
        const response = await axios.get("/getUsers", {
          params: {
            companycode: selectedCompanyCode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactOwners(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  // const fetchData = async () => {
  //   try {

  //     const response = await axios.get("/getUsers", {
  //       params: {
  //         companycode: companycode,
  //         userrole: userrole,
  //       },
  //     });
  //     console.log(response.data, "--------------")
  //     if (response && response.data) {
  //       setUser(response.data);
  //       setContactOwners(response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching leads:", error);
  //   }
  // };
  const fetchContactData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getcontacts", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContact(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const fetchmmeetingData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getmeeting", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        console.log(response.data, "--------------")
        if (response && response.data) {
          setMeeting(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  useEffect(() => {
    if (openCreateDialog) {
      const now = new Date();
      const nextHour = new Date(now.setHours(now.getHours() + 1, 0, 0, 0));
      const oneHourLater = new Date(nextHour.getTime() + 60 * 60 * 1000);

      setValues(prev => ({
        ...prev,
        from: nextHour,
        to: oneHourLater,
      }));
    }
  }, [openCreateDialog]);
  useEffect(() => {
    fetchDatas();
    fetchleadData();
    fetchData(); // Fetch data on component mount
    fetchContactData();
    fetchmmeetingData();
    if (selectedCompanyCode) {
      fetchData(); // Fetch data when selectedCompanyCode changes
    }
  }, [userrole, companycode, userid, username,]);
  useEffect(() => {
    if (selectedCompanyCode) {
      fetchData(); // Fetch data when selectedCompanyCode changes
    }
  }, [selectedCompanyCode]);
  // Sample data for Leads, Contacts, and Users
  const handleLeadsDialogOpen = () => {
    setOpenLeadsDialog(true);
  };
  const handleLeadsDialogClose = () => {
    setOpenLeadsDialog(false);
    // Extract emails from selected leads, contacts, and users
    const emails = [
      ...selectedLeads.map((id) => {
        const item = leads.find((d) => d.id === id);
        return item ? item.leademail : null; // Adjust according to your leads structure
      }),
      ...selectedContacts.map((id) => {
        const item = contact.find((d) => d.contactid === id);
        return item ? item.email : null;
      }),
      ...selectedUsers.map((id) => {
        const item = user.find((d) => d.id === id);
        return item ? item.email : null;
      })
    ].filter((email) => email !== null && email !== ''); // Filter out null/empty emails

    // Update participants state with selected emails
    setSelectedParticipants(emails);
     console.log(emails)
    // Extract emails from selected leads/contacts/users
  };
  const handleSelectLead = (id, type) => {
    if (type === 'Leads') {
      setSelectedLeads(prevSelectedLeads => {
        if (prevSelectedLeads.includes(id)) {
          // Deselect the lead if it's already selected
          return prevSelectedLeads.filter(selectedId => selectedId !== id);
        } else {
          // Select the lead
          return [...prevSelectedLeads, id];
        }
      });
    } else if (type === 'Users') {
      setSelectedUsers(prevSelectedUsers => {
        if (prevSelectedUsers.includes(id)) {
          // Deselect the user if it's already selected
          return prevSelectedUsers.filter(selectedId => selectedId !== id);
        } else {
          // Select the user
          return [...prevSelectedUsers, id];
        }
      });
    } else if (type === 'Contacts') {
      setSelectedContacts(prevSelectedContacts => {
        if (prevSelectedContacts.includes(id)) {
          // Deselect the contact if it's already selected
          return prevSelectedContacts.filter(selectedId => selectedId !== id);
        } else {
          // Select the contact
          return [...prevSelectedContacts, id];
        }
      });
    }
  };

  const handleShowLeadsWithoutEmail = (event) => {
    console.log(event.target.checked); // Add this for debugging
    setShowLeadsWithoutEmail(event.target.checked);
  };
  useEffect(() => {
    console.log("showLeadsWithoutEmail changed:", showLeadsWithoutEmail);
  }, [showLeadsWithoutEmail]);

  useEffect(() => {
    setFilteredAccounts(
      meeting.filter(account => {
        if (nameFilter === 'All') return true;
        return account.title && account.title.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, meeting]);

  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
  ];

  const fieldFilters = [
    "Title",
    "Host",
    "Start_Time",
    "End_Time",
    "All day",
    "Check-In Address",
    "Check-In By",
    "Check-In City",
    "Check-In Country",
    "Check-In State",
    "Check-In Sub-Locality",
    "Check-In Time",
    "Checked In to",
    "Contact Name",
    "Created By",
    "Created On",
    "Location",
    "Updated By",
    "Updated On",
    "Related To",
    "Tag",
    "Zip Code",
  ];

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };


  // const handleChange = (event) => {
  //   setSelectedView(event.target.value);
  // };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);


  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 0.3, minWidth: 120,
    },
    { field: "firstname", headerName: "Host", flex: 0.3, minWidth: 120 },
    { field: "start_time", headerName: "From", flex: 0.3, minWidth: 160 },
    { field: "end_time", headerName: "To", flex: 0.3, minWidth: 160 },
    // Add the emails field
    { field: "emails", headerName: "Emails", flex: 0.3, minWidth: 500 },
    { field: "relatedto", headerName: "Related To", flex: 0.3, minWidth: 120 },
    { field: "participants", headerName: "Participants", flex: 0.3, minWidth: 120 },
    { field: "location", headerName: "Location", flex: 0.3, minWidth: 120 },
    { field: "allday", headerName: "All Day", flex: 0.3, minWidth: 120 },
    { field: "description", headerName: "Description", flex: 0.3, minWidth: 120 },
    { field: "createdby", headerName: "Created By", flex: 0.3, minWidth: 120 },
    { field: "createdon", headerName: "Created On", flex: 0.3, minWidth: 160 },
    { field: "updatedby", headerName: "Updated By", flex: 0.3, minWidth: 120 },
    { field: "updatedon", headerName: "Updated On", flex: 0.3, minWidth: 160 },

  ];

  useEffect(() => {
    let updatedmeetings = meeting;

    // Apply system filters
    if (selectedSystemFilters.length > 0) {
      updatedmeetings = updatedmeetings.filter((accounts) => {
        // Add your system-defined filtering logic here.
        // For demonstration, I'm just filtering by a simple condition.
        // You'll need to customize this based on the system filters.
        return selectedSystemFilters.every((filter) =>
          accounts[filter.toLowerCase().replace(/\s+/g, '')]
        );
      });
    }

    // Apply field filters
    if (selectedFieldFilters.length > 0) {
      updatedmeetings = updatedmeetings.filter((lead) => {
        return selectedFieldFilters.every((filter) => {
          const field = filter.toLowerCase().replace(/\s+/g, '');
          return fieldSearchTerms[filter] ? lead[field]?.includes(fieldSearchTerms[filter]) : true;
        });
      });
    }

    setFilteredAccounts(updatedmeetings);
  }, [selectedSystemFilters, selectedFieldFilters, meeting, fieldSearchTerms]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuOpen = Boolean(anchorEl);

  return (
    <Box>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{ color: 'white', background: 'black', borderRadius: '5px' }}>
          {/* <InputLabel id="lead-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
          <Select
            labelId="lead-select-label"
            value={selectedView}
            onChange={handleChange}
            label="Public Views"
            style={{ color: 'white' }}
          >
            <MenuItem value="All Meetings">All Meetings</MenuItem>
            <MenuItem value="Cancelled Meetings">Cancelled Meetings</MenuItem>
            <MenuItem value="Completed Meetings">Completed Meetings</MenuItem>
            <MenuItem value="My Cancelled Meetings">My Cancelled Meetings</MenuItem>
            <MenuItem value="My Completed Meetings">My Completed Meetings</MenuItem>
            <MenuItem value="My Meetings">My Meetings</MenuItem>
            <MenuItem value="My Today's Meetings">My Today's Meetings</MenuItem>
            <MenuItem value="My Upcoming Meetings">My Upcoming Meetings</MenuItem>
            <MenuItem value="This Weeks Meetings">This Weeks Meetings</MenuItem>
            <MenuItem value="Today's Meetings">Today's Meetings</MenuItem>
            <MenuItem value="Upcoming Meetings">Upcoming Meetings</MenuItem>

          </Select>
        </FormControl>
        <Box>
          <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleCreateMeeting}>Create Meeting</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Meetings</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{ marginRight: '10px', marginLeft: '-5px' }}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}>

        <Grid item xs={2}>
          <Box
            sx={{
              p: 2,
              borderRight: "1px solid #e0e0e0",
              height: "76vh",
              background: "black",
              overflowY: "auto",
              marginTop: "20px"
            }}
          >
            <Typography variant="h6" color="white" style={{ fontSize: "16px" }}>Filter Meetings by</Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <Typography
              variant="body2"
              color="white"
              sx={{ mb: 1, cursor: "pointer", fontSize: "16px" }}
              onClick={toggleSystemFilters}
            >
              System Defined Filters <IconButton
                sx={{
                  color: "white",
                  transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>

            </Typography>
            {systemFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {filteredSystemDefinedFilters.map((filter) => (
                  <FormControlLabel
                    key={filter}
                    control={<Checkbox />}
                    label={filter}
                    sx={{ color: "white" }}
                  />
                ))}
              </Box>
            )}

            <Typography
              variant="body2"
              color="white"
              sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
              onClick={toggleFieldFilters}
            >
              Filter By Fields <IconButton
                sx={{
                  color: "white",
                  transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {fieldFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* {filteredFieldFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))} */}
                {filteredFieldFilters.map((filter) => (
                  <Box key={filter}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFieldFilters.includes(filter)}
                          onChange={handleFieldFilterChange}
                          name={filter}
                        />
                      }
                      label={filter}
                    />
                    {selectedFieldFilters.includes(filter) && (
                      <TextField
                        label={`Search ${filter}`}
                        value={fieldSearchTerms[filter] || ""}
                        onChange={(event) => handleFieldSearchChange(event, filter)}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '77vh', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{ color: 'white' }}>Total Records: {filteredAccounts.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredAccounts}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              getRowId={(row) => row.meetingid}
            />

          </Paper>
        </Grid>
      </Grid>

      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Meeting Information</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            value={values.title}
            onChange={handleInputChange}
          />
          <TextField
            autoFocus
            margin="dense"
            name="location"
            label="Location"
            type="text"
            fullWidth
            variant="outlined"
            value={values.location}
            onChange={handleInputChange}
          />
                  {/* Company Code Field */}
                  <Grid item xs={1.6}>
            <InputLabel htmlFor="companyCodes" style={{ marginBottom: '4px' }}>
              Company Code
            </InputLabel>
          </Grid>
          {userrole === "Superadmin" && companycode === 1 ? (
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={companyCodes}
                      getOptionLabel={(option) => option}
                      onChange={(event, value) => {
                        setSelectedCompanyCode(value || ""); // Update selected company code
                        handleChange({
                          target: {
                            name: "companyCodes",
                            value: value || "", // Ensure it handles the case where no value is selected
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.companyCodes && !!errors.companyCodes}
                          helperText={touched.companyCodes && errors.companyCodes ? errors.companyCodes : ""}
                        />
                      )}
                      value={values.companyCodes} // Controlled value
                      isOptionEqualToValue={(option, value) => option === value} // Check equality
                    />

                  </FormControl>
                </Grid>
              ) : (
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
                value={companycode}
                name="companyCodes"
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={values.allDay}
                onChange={handleInputChange}
                name="allDay"
              />
            }
            label="All day"
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {values.allDay ? (
              <>
                <DatePicker
                  label="From"
                  value={values.from}
                  onChange={(date) => handleDateChange('from', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
                <DatePicker
                  label="To"
                  value={values.to}
                  onChange={(date) => handleDateChange('to', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
              </>
            ) : (
              <>
                <DateTimePicker
                  label="From"
                  value={values.from}
                  onChange={(date) => handleDateChange('from', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
                <DateTimePicker
                  label="To"
                  value={values.to}
                  onChange={(date) => handleDateChange('to', date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
              </>
            )}
          </LocalizationProvider>
          <Grid item xs={1.6}>
            <InputLabel htmlFor="host" style={{ marginBottom: '4px' }}>
              Host
            </InputLabel>
          </Grid>
          <Autocomplete
            id="host"
            options={contactOwners}
            getOptionLabel={(option) => option.firstname || ""}
            value={contactOwners.find(owner => owner.userid === values.host) || null}
            onChange={(event, newValue) => {
              handleChange({
                target: {
                  name: "host",
                  value: newValue ? newValue.userid : "",
                },
              });
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                error={touched.host && Boolean(errors.host)}
                helperText={touched.host && errors.host}
              />
            )}
          />
          <TextField
            margin="dense"
            name="participants"
            label="Participants"
            type="text"
            fullWidth
            variant="outlined"
            value={`${selectedParticipants.length} Selected`}
            InputProps={{
              endAdornment: (
                <Button size="small" color="secondary" onClick={handleLeadsDialogOpen}>
                  + Add
                </Button>
              ),
            }}
          />
  
          <TextField
            select
            margin="dense"
            name="relatedTo"
            label="Related To"
            fullWidth
            variant="outlined"
            value={values.relatedTo}
            onChange={handleInputChange}
          >
            <MenuItem value="None">None</MenuItem>
            {/* Add more related to options as needed */}
          </TextField>
          <TextField
            select
            margin="dense"
            name="repeat"
            label="Repeat"
            fullWidth
            variant="outlined"
            value={values.repeat}
            onChange={handleInputChange}
          >
            <MenuItem value="None">None</MenuItem>
            {/* Add more repeat options as needed */}
          </TextField>
          <TextField
            margin="dense"
            name="description"
            label="Description"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={values.description}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} variant="contained" color="primary">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      {/* Leads Dialog */}
      <Dialog open={openLeadsDialog} onClose={handleLeadsDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select {filterType}</DialogTitle>
        <DialogContent>
          {/* Dropdown Filter */}
          <TextField
            select
            margin="dense"
            name="filter"
            label="Leads"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="Leads">Leads</MenuItem>
            <MenuItem value="Contacts">Contacts</MenuItem>
            <MenuItem value="Users">Users</MenuItem>
          </TextField>

          {/* Search Bar */}
          <TextField
            margin="dense"
            name="search"
            label="Search"
            type="text"
            fullWidth
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          {filterType === 'Leads' &&
            leads
              .filter(l => l.leadname.toLowerCase().includes(searchQuery.toLowerCase()))
              .filter(l => showLeadsWithoutEmail || l.leademail) // Show leads with or without emails
              .map(l => (
                <div key={l.id}>
                  <Checkbox
                    checked={selectedLeads.includes(l.id)}
                    onChange={() => handleSelectLead(l.id, 'Leads')} // Pass 'Leads' as type
                  />
                  {l.leadname} ({l.leademail ? l.leademail : "No email"})
                </div>
              ))
          }

          {filterType === 'Users' &&
            user
              .filter(u => u.firstname.toLowerCase().includes(searchQuery.toLowerCase()))
              .map(u => (
                <div key={u.id}>
                  <Checkbox
                    checked={selectedUsers.includes(u.id)}
                    onChange={() => handleSelectLead(u.id, 'Users')} // Pass 'Users' as type
                  />
                  {u.firstname} {u.lastname} ({u.email})
                </div>
              ))
          }

          {filterType === 'Contacts' &&
            contact
              .filter(c => c.accountname.toLowerCase().includes(searchQuery.toLowerCase()))
              .map(c => (
                <div key={c.contactid}>
                  <Checkbox
                    checked={selectedContacts.includes(c.contactid)}
                    onChange={() => handleSelectLead(c.contactid, 'Contacts')} // Pass 'Contacts' as type
                  />
                  {c.accountname} ({c.email})
                </div>
              ))
          }

          {/* Show leads without email option */}
          <FormControlLabel
            control={
              <Checkbox
                checked={showLeadsWithoutEmail}
                onChange={handleShowLeadsWithoutEmail}
              />
            }
            label="Show Leads without email addresses as well."
          />

          {/* Invite by Email Field */}
          <TextField
            label="Invite by Email Address"
            placeholder="Use commas to separate email addresses."
            fullWidth
            margin="dense"
            value={inviteEmails}
            onChange={(e) => setInviteEmails(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeadsDialogClose} variant="contained" color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllMeeting;
