import React, { useState, useEffect } from "react";
import { Box, Typography,  IconButton, InputLabel,FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from 'react-helmet-async';
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const AllTask = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [deletetaskId, setDeleteTaskId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const [selectedView, setSelectedView] = useState('All Tasks');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
 const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState(tasks);
  const [fieldSearchTerms, setFieldSearchTerms] = useState({});
  const [selectedFieldFilters, setSelectedFieldFilters] = useState([]);
  const [selectedSystemFilters, setSelectedSystemFilters] = useState([]);
  const fetchData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/gettasks", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTasks(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchtodaytask = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/gettodaytask", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTasks(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };
  const fetchthisweekcontact = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getthisweektask", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTasks(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };

  const fetchlastweektask = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getlastweektask", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTasks(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };

  const fetchrecentaddedtask = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getaddedtask", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTasks(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };

  const fetchrecentmodifiedtask = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getmodifiedtask", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTasks(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };
  useEffect(() => {
    setFilteredTasks(
      tasks.filter(task => {
        if (nameFilter === 'All') return true;
        return task.taskName && task.taskName.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, tasks]);

  useEffect(() => {
    if (selectedView === "Today's Task") {
      fetchtodaytask(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "New This Week") {
      fetchthisweekcontact(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "New Last Week") {
      fetchlastweektask(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "Recently Created Task") {
      fetchrecentaddedtask(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "Recently Modified Task") {
      fetchrecentmodifiedtask(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else {
      fetchData(); // Fetch other leads based on selected view
    }
  }, [companycode, userrole,selectedView]); // Dependency array includes selectedView

  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
    "Locked",
  ];

  const fieldFilters = [
    "Closed Time",
    "Contact Name",
    "Created By",
    "Created Time",
    "Due Date",
    "Modified By",
    "Modified Time",
    "Priority",
    "Related To",
    "Status",
    "Subject",
    "Tag",
    "Task Owner",
  ];
  const [filteredFieldFilters, setFilteredFieldFilters] = useState(fieldFilters);

  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value.toLowerCase());
  // };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase(); // Get the new search term
    setSearchTerm(newSearchTerm); // Update the search term
  
    // Filter the fieldFilters using the new search term
    const filteredFieldFilters = fieldFilters.filter((filter) =>
        filter.toLowerCase().includes(newSearchTerm) // Use newSearchTerm for filtering
    );
  
    // Update the state with filtered field filters
    setFilteredFieldFilters(filteredFieldFilters); // Update the filtered results state
    console.log(filteredFieldFilters, "-----"); // Check the filtered results
  };
  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  // const filteredFieldFilters = fieldFilters.filter((filter) =>
  //   filter.toLowerCase().includes(searchTerm)
  // );
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };


  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);


  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };



  
const columns = [
    { 
      field: "subject", 
      headerName: "Subject", 
      flex: 0.3,minWidth: 140,
      renderCell: (params) => (
        <NavLink to={`/taskdetails/${params.row.taskid}`}>
          {params.row.subject}
        </NavLink>
      )
    },
    { field: "duedate", headerName: "Due Date", flex: 0.3,minWidth: 160 },
    { field: "statustask", headerName: "Status", flex: 0.3,minWidth: 140 },
    { field: "priority", headerName: "Priority", flex: 0.3,minWidth: 140 },
    {
      headerName: "Actions",
      minWidth: 120,flex: 0.3,
      renderCell: (props) => (
        <>
          <EditIcon
            style={{ marginRight: "10px" }}
            onClick={() => handleEdit(props.row.taskid)}
          />
          <DeleteIcon onClick={() => handleDelete(props.row.taskid)} />
        </>
      ),
    },
    { field: "contactaccountname", headerName: "Contact", flex: 0.3,minWidth: 140 },
    { field: "accountname", headerName: "Related To", flex: 0.3,minWidth: 140 },
    { field: "firstname", headerName: "Task Owner", flex: 0.3,minWidth: 140 },
    { field: "description", headerName: "Description", flex: 0.3,minWidth: 200 },
    { field: "createdon", headerName: "Created On", flex: 0.3,minWidth: 140 },
    { field: "createdby", headerName: "Created By", flex: 0.3,minWidth: 140 },
    { field: "updatedon", headerName: "Modified On", flex: 0.3,minWidth: 140 },
    { field: "updatedby", headerName: "Modified By", flex: 0.3,minWidth: 140 },
   
  ];
  const handleEdit = (task) => {
    navigate(`/updatetask/${task}`);
  };

  const handleDelete = (taskId) => {
    setDeleteTaskId(taskId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deletetask/${deletetaskId}`);
      if (response.status === 200) {
        setTasks((prevtasks) =>
          prevtasks.filter((tasks) => tasks.taskid !== deletetaskId)
        );
        setDeleteSuccessMessage("Task deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete task");
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFieldSearchChange = (event, filterName) => {
    console.log("----------------")
    setFieldSearchTerms((prev) => ({
      ...prev,
      [filterName]: event.target.value
    }));
  };

  const handleFieldFilterChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedFieldFilters((prev) => [...prev, name]);
      setFieldSearchTerms((prev) => ({ ...prev, [name]: "" }));
    } else {
      setSelectedFieldFilters((prev) => prev.filter((filter) => filter !== name));
      setFieldSearchTerms((prev) => {
        const { [name]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  useEffect(() => {
    let updatedtasks = tasks;
  
    // Apply system filters
    if (selectedSystemFilters.length > 0) {
      updatedtasks = updatedtasks.filter((accounts) => {
        // Add your system-defined filtering logic here.
        // For demonstration, I'm just filtering by a simple condition.
        // You'll need to customize this based on the system filters.
        return selectedSystemFilters.every((filter) =>
          accounts[filter.toLowerCase().replace(/\s+/g, '')]
        );
      });
    }
  
// Apply field filters
if (selectedFieldFilters.length > 0) {
  updatedtasks = updatedtasks.filter((task) => {
    return selectedFieldFilters.every((filter) => {
      const field = filter.toLowerCase().replace(/\s+/g, '');
      return fieldSearchTerms[filter] ? task[field]?.includes(fieldSearchTerms[filter]) : true;
    });
  });
}
  
    setFilteredTasks(updatedtasks);
  }, [selectedSystemFilters, selectedFieldFilters,tasks,fieldSearchTerms]);
  const menuOpen = Boolean(anchorEl);

  return (
    <Box>
    <Helmet>
      <title>Tasks - Contacttab.com</title>
    </Helmet>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
      {/* <InputLabel id="task-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
      <Select
        labelId="task-select-label"
        value={selectedView}
        onChange={handleChange}
        label="Public Views"
        style={{color: 'white'}}
      >
        <MenuItem value="All Tasks">All Tasks</MenuItem>
        <MenuItem value="Today's Task">Today's Task</MenuItem>
            <MenuItem value="New This Week">New This Week</MenuItem>
            <MenuItem value="New Last Week">New Last Week</MenuItem>
            <MenuItem value="Recently Created Task">Recently Created Task</MenuItem>
            <MenuItem value="Recently Modified Task">Recently Modified Task</MenuItem>   
        
      </Select>
    </FormControl>
        <Box>
        <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/create-task')}>Create Task</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Tasks</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}> 
     
        <Grid item xs={2}>
      <Box
        sx={{
          p: 2,
          borderRight: "1px solid #e0e0e0",
          height: "76vh",
          background: "black",
          overflowY: "auto",
          marginTop: "20px"
        }}
      >
        <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Tasks by</Typography>
        <TextField
          variant="outlined"
          placeholder="Search"
          size="small"
          fullWidth
          sx={{ mb: 2 }}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <Typography
          variant="body2"
          color="white"
          sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
          onClick={toggleSystemFilters}
        >
        System Defined Filters <IconButton
            sx={{
              color: "white",
              transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
         
        </Typography>
        {systemFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredSystemDefinedFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )}

        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
          onClick={toggleFieldFilters}
        >
          Filter By Fields <IconButton
            sx={{
              color: "white",
              transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Typography>
        {/* {fieldFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={<Checkbox />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )} */}

{fieldFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <Box key={filter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFieldFilters.includes(filter)}
                      onChange={handleFieldFilterChange}
                      name={filter}
                    />
                  }
                  label={filter}
                />
                {selectedFieldFilters.includes(filter) && (
                  <TextField
                    label={`Search ${filter}`}
                    value={fieldSearchTerms[filter] || ""}
                    onChange={(event) => handleFieldSearchChange(event, filter)}
                  />
                )}
              </Box>
            ))}
            </Box>
            )}
      </Box>
    </Grid>
            <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '77vh', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredTasks.length}</Typography>
            <DataGrid
              rows={filteredTasks}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row.taskid} // Specify taskid as the unique id
            />
            
            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this task?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllTask;
