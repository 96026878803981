import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
  Grid,
  FormHelperText,
  styled,
} from "@mui/material";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import axios from "../../../utils/axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { useUser } from "../../../Provider";
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));
const Adduser = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    age: "",
    phoneno: "",
    address: "",
    role: "",
    password: "",
    companyCodes: companycode || "", // Initialize company code
  };

  const ValidationSchema = yup.object().shape({
    firstname: yup.string().required("First name is required"),
    role: yup.string().required("Role is required"),
    companyCodes: yup.string().required("Company Code is required"),
    email: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
    phoneno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Mobile Number is required"),
    password: yup.string().min(6).required("Please enter the password"),
    conformpassword: yup.string().required().oneOf([yup.ref('password'), null]),
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]);
  const [birthDate, setBirthDate] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        setData(response.data);
        setFilteredData(response.data);
        console.log(response.data)
        const formattedCodes = response.data.map((company) =>
          company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [companycode]);

  const selectDate = (sd) => {
    setBirthDate(sd);
  };

  const selectJoiningDate = (sd) => {
    setJoiningDate(sd);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username,
          userrole,
          companycode,
          dob: birthDate,
          joiningdate: joiningDate,
        };
        const formDataToSend = new FormData();
        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        console.log(JSON.stringify(leadDataWithUsername));
        const response = await axios.post("/userRegistration", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("Task data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <Header
            title="Add User"
            subtitle="Create a New User Profile"
          />
          <h3>User Details</h3>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="firstname" style={{ marginBottom: '4px' }}>
                First Name
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.firstname}
                name="firstname"
                error={
                  touched.firstname &&
                  !!errors.firstname
                }
                helperText={
                  touched.firstname && errors.firstname
                    ? "First name is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="lastname" style={{ marginBottom: '4px' }}>
                Last Name
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
               
                onChange={handleChange}
                value={values.lastname}
                name="lastname"
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="email" style={{ marginBottom: '4px' }}>
                Email
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
              
                onChange={handleChange}
                value={values.email}
                name="email"
                error={touched.email && !!errors.email}
                helperText={
                  touched.email && errors.email
                    ? "Email is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="phoneno" style={{ marginBottom: '4px' }}>
                Phone Number
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
               
                onChange={handleChange}
                value={values.phoneno}
                name="phoneno"
                error={
                  touched.phoneno && !!errors.phoneno
                }
                helperText={
                  touched.phoneno && errors.phoneno
                    ? "Phone number is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="address" style={{ marginBottom: '4px' }}>
                Address
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
               
                onChange={handleChange}
                value={values.address}
                name="address"
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="age" style={{ marginBottom: '4px' }}>
                Age
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
              
                onChange={handleChange}
                value={values.age}
                name="age"
                sx={{ gridColumn: "span 2" }}
              />
            </Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="password" style={{ marginBottom: '4px' }}>
                Password
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
             
                onChange={handleChange}
                value={values.password}
                name="password"
                error={
                  touched.password &&
                  !!errors.password
                }
                helperText={
                  touched.password && errors.password
                    ? "password required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="conformpassword" style={{ marginBottom: '4px' }}>
                Conform Pasword
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="text"
             
                onChange={handleChange}
                value={values.conformpassword}
                name="conformpassword"
                error={
                  touched.conformpassword &&
                  !!errors.conformpassword
                }
                helperText={
                  touched.conformpassword && errors.conformpassword
                    ? "Password and Conform Password should same"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="birthDate" style={{ marginBottom: '4px' }}>
                Date Of Birth
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                  value={dayjs(birthDate)}
                  slotProps={{ textField: { size: "small" } }}
                  style={{ marginTop: "2rem" }}
                  onChange={(e) => selectDate(e["$d"])}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
               {/* <CustomTextField
                  fullWidth
                  name="birthDate"
                  type="date"
                  value={values.birthDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                /> */}
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="joiningDate" style={{ marginBottom: '4px' }}>
              Joining Date
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="combo-box-demo"
                 
                  value={dayjs(joiningDate)}
                  slotProps={{ textField: { size: "small" } }}
                  style={{ marginTop: "2rem" }}
                  onChange={(e) => selectJoiningDate(e["$d"])}
                  sx={{ gridColumn: "span 2" }}
                />
              </LocalizationProvider>
              {/* <CustomTextField
                  fullWidth
                  name="joiningDate"
                  type="date"
                  value={values.joiningDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                /> */}
            </Grid></Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="role" style={{ marginBottom: '4px' }}>
                Role
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              {/* {userrole === "Superadmin" && companycode === 1 && ( */}
              <FormControl
                fullWidth
                sx={{ gridColumn: "span 2" }}
              >
                <Select
                  id="role"
                  onChange={handleChange}
                  value={values.role}
                  name="role"
                  error={touched.role && !!errors.role}
                  helperText={
                    touched.role && errors.role
                      ? errors.role
                      : ""
                  }
                >
                  <MenuItem value="">Select Role</MenuItem>
                  <MenuItem value="Superadmin">Super Admin</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* )} */}
            <Grid item xs={1.6}>
              <InputLabel htmlFor="companycode" style={{ marginBottom: '4px' }}>
              Company Code
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              {userrole === "Superadmin" && companycode === 1 ? (
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2" }}
                >
                  <Select
                    labelId="companyCode-label"
                    id="companyCodes"
                    onChange={handleChange}
                    value={values.companyCodes}
                    name="companyCodes"
                    error={
                      touched.companyCodes &&
                      !!errors.companyCodes
                    }
                    helperText={
                      touched.companyCodes &&
                        errors.companyCodes
                        ? errors.companyCodes
                        : ""
                    }
                    label="Company Code"
                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                  >
                    <MenuItem value="">Select Company Code</MenuItem>
                    {companyCodes.map((code, index) => (
                      <MenuItem
                        key={index}
                        value={code}
                      >
                        {code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  fullWidth

                  type="text"
                  value={companycode}
                  name="companyCodes"
                  sx={{ gridColumn: "span 2" }}
                />
              )}
            </Grid></Grid>
        </Box>
        <Box display="flex" justifyContent="center" mt="20px" mb="50px">
            <Button type="submit" color="secondary" variant="contained">
              SUBMIT
            </Button>
            <Button type="button" onClick={() => resetForm()} variant="contained" color="primary" style={{ marginLeft: '20px',background: 'Red'}} >
              Cancel
            </Button>
          </Box>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        message={successMessage}
      />
      <br />
    </>

  );
};

export default Adduser;
