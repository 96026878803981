import '../../App.css';
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../utils/axios";
import {
    Box,
    Button,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    styled,
} from "@mui/material";
import { useUser } from "../../contexts/auth";
import { useParams } from "react-router-dom";
const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: 'white',
            color: 'white'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
}));

const TaskDetails = () => {
    const { taskid } = useParams();
    const {
        userrole,
        username,
        userid,
        companycode,
        isAuthRole,
        isAuthorized,
        token,
    } = useUser();
    const initialValues = {
        taskowner: userid || "",
        subject: '',
        duedate: null,
        contact: '',
        account: '',
        status: 'Not Started',
        priority: 'High',
        reminder: false,
        reminderdate: null,
        remindertime: null,
        remindertype: 'Pop Up',
        repeat: false,
        repeattype: 'Daily',
        repeatends: 'Never',
        repeattimes: 1,
        repeatenddate: null,
        description: '',
        companyCodes: companycode || "", // Initialize company code
    }
    const [successMessage, setSuccessMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [reminderEnabled, setReminderEnabled] = useState(false);
    const [repeatEnabled, setRepeatEnabled] = useState(false);
    const [taskOwners, setTaskOwners] = useState([]);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || "");
    const [companyCodes, setCompanyCodes] = useState([]);
    const [contacts, setContactsId] = useState([]);
    const [accounts, setAccount] = useState([]);

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        resetForm,
        setValues
    } = useFormik({
        initialValues,
    });
    useEffect(() => {
        const fetchLeadData = async () => {
          try {
            const response = await axios.get(`/gettaxbyid/${taskid}`);
           console.log(response.data)
           const leadData = response.data;
            if (response && response.data) {
              setValues({
                ...leadData,
               
              });
              console.log(response.data)
            } else {
              // Handle error for failed request
            }
          } catch (error) {
            // Handle network errors
          }
        };
        fetchLeadData();
      }, [taskid]);

    return (
        <>
            <Helmet>
                <title>Create Task - Contacttab.com</title>
            </Helmet>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={3} sx={{ p: 3, m: 2 }} style={{marginTop: '6%'}}> */}
            {/* <Typography variant="h5" gutterBottom>
          Create Task
        </Typography> */}
            <form onSubmit={handleSubmit}>
                <Box m="20px">
                    <h1>Create Tax</h1>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="taskowner" style={{ marginBottom: '4px' }}>
                                Task Owner
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <CustomTextField
                                    fullWidth
                                    name="taskowner"
                                    type="text"
                                    value={values.firstname}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="subject" style={{ marginBottom: '4px' }}>
                                Subject
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                fullWidth
                                name="subject"
                                type="text"
                                value={values.subject}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="contact" style={{ marginBottom: '4px' }}>
                                Contact
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                        <CustomTextField
                                fullWidth
                                name="contact"
                                type="text"
                                value={values.contactaccountname}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="account" style={{ marginBottom: '4px' }}>
                                Account
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                fullWidth
                                name="account"
                                type="text"
                                value={values.accountname}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="status" style={{ marginBottom: '4px' }}>
                                Status
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                fullWidth
                                name="status"
                                type="text"
                                value={values.status}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="priority" style={{ marginBottom: '4px' }}>
                                Priority
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                fullWidth
                                name="priority"
                                type="text"
                                value={values.priority}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="duedate" style={{ marginBottom: '4px' }}>
                                Due Date
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                fullWidth
                                name="duedate"
                                type="text"
                                value={values.duedate}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        {/* Company Code Field */}
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="companyCodes" style={{ marginBottom: '4px' }}>
                                Company Code
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField
                                fullWidth
                                name="companycode"
                                type="text"
                                value={values.companycode}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid></Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1.6}>
                            <InputLabel htmlFor="duedate" style={{ marginBottom: '4px' }}>
                                Description
                            </InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                name="description"
                                value={values.description}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </>
    );
};

export default TaskDetails;
