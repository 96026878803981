import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../utils/axios";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  Autocomplete,
} from "@mui/material";
import { useUser } from "../../contexts/auth";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const AccountDetails = () => {
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  const [companycodes, setCompanyCodes] = useState([]);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || "");
  const {accountid} = useParams();
  const initialValues = {
    accountowner: "",
    accountname: "",
    accountsite: "",
    parentaccount: "",
    accountnumber: "",
    accounttype: "",
    industry: "",
    annualrevenue: "",
    rating: "",
    phone: "",
    fax: "",
    website: "",
    tickersymbol: "",
    ownership: "",
    employees: "",
    sicCode: "",
    billingstreet: "",
    billingcity: "",
    billingstate: "",
    billingcode: "",
    billingcountry: "",
    shippingstreet: "",
    shippingcity: "",
    shippingstate: "",
    shippingcode: "",
    shippingcountry: "",
    description: "",
    companycodes: companycode || "", // Initialize company code
  }
  const [accountImage, setAccountImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const validationSchema = yup.object({
    accountname: yup.string().required("Account Name is required"),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });
 
  const fetchData = async () => {
    try {
      const response = await axios.get("/getCompanyRegistrations");
      console.log(response.data);
      const formattedCodes = response.data.map(
        (company) => company.establishmentid
      );
      setCompanyCodes(formattedCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchLeadData = async () => {
    try {
      const response = await axios.get(`/getaccountbyid/${accountid}`);
     console.log(response.data)
     const accountdata = response.data;
      if (response && response.data) {
        setValues({
          ...accountdata,
        });
        console.log(response.data)
      } else {
        // Handle error for failed request
      }
    } catch (error) {
      // Handle network errors
    }
  };
  useEffect(() => {
    fetchData();
    fetchLeadData();
  }, [userrole, companycode, userid, username]);
  
  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setAccountImage(selectedFiles);
      handleChange({
        target: {
          name: "accountimage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  // const handleImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setAccountImage(URL.createObjectURL(event.target.files[0]));
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>Create Account - Contacttab.com</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h1>Create Account</h1>

          <h4>Account Image</h4>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <input
              accept="image/*"
              id="account-image-upload"
              name="accountimage"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <label htmlFor="account-image-upload">
              <Button
                variant="contained"
                component="span"
              >
                Upload Image
              </Button>
            </label>
            {accountImage && (
              <Box sx={{ ml: 2 }}>
                {/* <img src={accountImage} alt="Account" style={{ width: 100, height: 100, objectFit: 'cover' }} /> */}
                {selectedFileNamePan && (
                  <div>Selected file: {selectedFileNamePan}</div>
                )}
              </Box>
            )}
          </Box>

          <h4 style={{ margin: '20px 0px' }}>Account Information</h4>
          <Grid container spacing={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="accountowner" style={{ marginBottom: '4px' }}>
              Account Owner
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
              <CustomTextField
                  name="accountowner"
                  value={values.firstname}

                />
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="rating" style={{ marginBottom: '4px' }}>
              Rating
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
              <CustomTextField
                  name="rating"
                  value={values.rating}

                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={1.6}>
                <InputLabel htmlFor="companycodes" style={{ marginBottom: '4px' }}>
                  Company Code
                </InputLabel>
              </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="text"
                    value={companycode}
                    name="companycodes"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="accountname" style={{ marginBottom: '4px' }}>
              Account Name
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="accountname"
                value={values.accountname}
                error={touched.accountname && Boolean(errors.accountname)}
                helperText={touched.accountname && errors.accountname}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="phone" style={{ marginBottom: '4px' }}>
                Phone
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="phone"
                value={values.phone}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="accountsite" style={{ marginBottom: '4px' }}>
                Account Site
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="accountsite"
                value={values.accountsite}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="fax" style={{ marginBottom: '4px' }}>
                Fax
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="fax"
                value={values.fax}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="parentaccount" style={{ marginBottom: '4px' }}>
               Parent Account
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="parentaccount"
                value={values.parentaccount}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="website" style={{ marginBottom: '4px' }}>
              Website
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="website"
                value={values.website}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="accountnumber" style={{ marginBottom: '4px' }}>
              Account Number
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="accountnumber"
                value={values.accountnumber}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="tickersymbol" style={{ marginBottom: '4px' }}>
              Ticker Symbol
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="tickersymbol"
                value={values.tickersymbol}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="accounttype" style={{ marginBottom: '4px' }}>
              Account Type
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
              <CustomTextField
                  name="accounttype"
                  value={values.accounttype}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="ownership" style={{ marginBottom: '4px' }}>
              Ownership
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
              <CustomTextField
                  name="ownership"
                  value={values.ownership}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="industry" style={{ marginBottom: '4px' }}>
              Industry
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
              <CustomTextField
                  name="industry"
                  value={values.industry}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="employees" style={{ marginBottom: '4px' }}>
              Employees
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
            <CustomTextField
                fullWidth
                name="employees"
                value={values.employees}
              />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="annualrevenue" style={{ marginBottom: '4px' }}>
              Annual Revenue
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="annualrevenue"
                value={values.annualrevenue}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="sicCode" style={{ marginBottom: '4px' }}>
                SIC Code
                </InputLabel>
              </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              name="sicCode"
              value={values.sicCode}
            />
          </Grid>
          </Grid>
          </Grid>

          <h4 style={{ margin: '20px 0px' }}>Address Information</h4>
          <Grid container spacing={2}>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="billingstreet" style={{ marginBottom: '4px' }}>
                Billing Street
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="billingstreet"
                value={values.billingstreet}
              />
            </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="shippingstreet" style={{ marginBottom: '4px' }}>
                Shipping Street
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="shippingstreet"
                value={values.shippingstreet}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="billingcity"style={{ marginBottom: '4px' }}>
                Billing City
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="billingcity"
                value={values.billingcity}
              />
            </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="shippingcity" style={{ marginBottom: '4px' }}>
                 Shipping City
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="shippingcity"
                value={values.shippingcity}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="billingstate" style={{ marginBottom: '4px' }}>
                Billing State
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="billingstate"
                value={values.billingstate}
              />
            </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="shippingstate" style={{ marginBottom: '4px' }}>
                Shipping State
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="shippingstate"
                value={values.shippingstate}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="billingcode" style={{ marginBottom: '4px' }}>
                Billing Code
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="billingcode"
                value={values.billingcode}
              />
            </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="shippingcode" style={{ marginBottom: '4px' }}>
                Shipping Code
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="shippingcode"
                value={values.shippingcode}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="billingcountry" style={{ marginBottom: '4px' }}>
                Billing Country
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="billingcountry"
                value={values.billingcountry}
              />
            </Grid>
              <Grid item xs={1.6}>
                <InputLabel htmlFor="shippingcountry" style={{ marginBottom: '4px' }}>
                Shipping Country
                </InputLabel>
              </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                name="shippingcountry"
                value={values.shippingcountry}
              />
            </Grid>
            </Grid>
          </Grid>

          <h4 style={{ margin: '20px 0px' }}>Description Information</h4>
          <Grid container spacing={2}>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={1.6}>
                <InputLabel htmlFor="description" style={{ marginBottom: '4px' }}>
                Description
                </InputLabel>
              </Grid>
            <Grid item xs={10}>
              <CustomTextField
                fullWidth
                name="description"
                multiline
                rows={4}
                value={values.description}
              />
            </Grid>
          </Grid>
          </Grid>
        </Box>
      </form>
      <br />
    </>
  );
};

export default AccountDetails;