import React, { useState,useEffect } from 'react';
import { Box, Typography, Grid, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Helmet } from 'react-helmet-async';
import { Edit as EditIcon, Delete as DeleteIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { FunnelChart, Funnel, Tooltip, LabelList } from 'recharts';
import axios from "../../utils/axios";
import { useUser } from '../../contexts/auth';
// const initialTasks = [
//   { id: 1, subject: "Register for upcoming CRM Webinars", dueDate: "08/07/2024", status: "Not Started", priority: "Low", relatedTo: "King (Sample)", contactName: "Kris Marrier (Sample)" },
//   { id: 2, subject: "Refer CRM Videos", dueDate: "10/07/2024", status: "In Progress", priority: "Normal", relatedTo: "Morlong Associates", contactName: "Mitsue Tollner (Sample)" },
//   { id: 3, subject: "Competitor Comparison Document", dueDate: "06/07/2024", status: "Not Started", priority: "Highest", relatedTo: "Feltz Printing Service", contactName: "Capla Paprocki (Sample)" },
//   { id: 4, subject: "Get Approval from Manager", dueDate: "07/07/2024", status: "Not Started", priority: "Low", relatedTo: "Chapman", contactName: "Simon Morasca (Sample)" },
//   // More mock data can be added here
// ];

// const mockMeetings = [
//   { title: "Demo", from: "08/07/2024 03:24 PM", to: "08/07/2024 04:24 PM", relatedTo: "Printing Dimensions", contactName: "Donette Foller (Sample)" },
//   { title: "Webinar", from: "08/07/2024 05:24 PM", to: "08/07/2024 06:24 PM", relatedTo: "Commercial Press (Sample)", contactName: "Leota Dilliard (Sample)" },
//   { title: "TradeShow", from: "08/07/2024 04:24 PM", to: "08/07/2024 07:24 PM", relatedTo: "Chemel", contactName: "James Venere (Sample)" },
//   { title: "Demo", from: "08/07/2024 03:24 PM", to: "08/07/2024 04:24 PM", relatedTo: "Printing Dimensions", contactName: "Donette Foller (Sample)" },
//   { title: "Webinar", from: "08/07/2024 05:24 PM", to: "08/07/2024 06:24 PM", relatedTo: "Commercial Press (Sample)", contactName: "Leota Dilliard (Sample)" },
//   { title: "TradeShow", from: "08/07/2024 04:24 PM", to: "08/07/2024 07:24 PM", relatedTo: "Chemel", contactName: "James Venere (Sample)" },
// ];

const pipelineData = [
  { name: 'Qualification', value: 1 },
  { name: 'Needs Analysis', value: 2 },
  { name: 'Value Proposition', value: 1 },
  { name: 'Identify Decision Makers', value: 2 },
  { name: 'Proposal/Price Quote', value: 1 },
  { name: 'Negotiation/Review', value: 1 },
];


const Dashboard = () => {
  const theme = useTheme();
  const [tasks, setTasks] = useState([]);
  const [leads, setLeads] = useState([]);
  const [mockMeetings, setMeeting] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  const handleEdit = (taskid) => {
    // Logic for editing a task
    console.log(`Edit task with id: ${taskid}`);
  };

  const handleDelete = (taskid) => {
    // Logic for deleting a task
    setTasks(tasks.filter(task => task.taskid !== taskid));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/gettasks", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTasks(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchmmeetingData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getmeeting", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        console.log(response.data, "--------------")
        if (response && response.data) {
          setMeeting(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  const fetchtodaylead = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/gettodaylead", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setLeads(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching junk leads:", error);
    }
  };
  
  useEffect(() => {
      fetchData(); // Fetch other leads based on selected view
      fetchtodaylead();
      fetchmmeetingData();
  }, [companycode, userrole]);
  return (
    <Box m="20px">
     <Helmet>
      <title>Dashboard - Contacttab.com</title>
    </Helmet>

      <Typography variant="h4" gutterBottom>
        DASHBOARD
      </Typography>

      <Grid container spacing={3}>
        {["My Open Deals", "My Untouched Deals", "My Calls Today", "My Leads"].map((title, index) => (
          <Grid item xs={3} key={index}>
            <Paper elevation={3} style={{ padding: '20px', textAlign: 'center', position: 'relative', background: 'white', color: 'black' }}>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="h4">0</Typography>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                style={{ position: 'absolute', top: '10px', right: '10px' }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleEdit(index)} >Edit</MenuItem>
                <MenuItem onClick={() => handleDelete(index)} >Delete</MenuItem>
              </Menu>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} mt={3}>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: '20px', maxHeight: '400px', overflow: 'auto', background: 'white', color: 'black' }}>
            <Typography variant="h5">My Open Tasks</Typography>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Related To</TableCell>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow key={task.id} style={{color: 'black' }}>
                      <TableCell style={{color: 'black' }}>{task.subject}</TableCell>
                      <TableCell style={{color: 'black' }}>{task.duedate}</TableCell>
                      <TableCell style={{color: 'black' }}>{task.status}</TableCell>
                      <TableCell style={{color: 'black' }}>{task.priority}</TableCell>
                      <TableCell style={{color: 'black' }}>{task.accountname}</TableCell>
                      <TableCell style={{color: 'black' }}>{task.contactaccountname}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEdit(task.taskid)} size="small" style={{color: 'black' }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(task.taskid)} size="small" style={{color: 'black' }}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: '20px', maxHeight: '400px', overflow: 'auto', background: 'white', color: 'black' }}>
            <Typography variant="h5">My Meetings</Typography>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Related To</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mockMeetings.map((meeting, index) => (
                    <TableRow key={index}>
                      <TableCell style={{color: 'black' }}>{meeting.title}</TableCell>
                      <TableCell style={{color: 'black' }}>{meeting.start_time}</TableCell>
                      <TableCell style={{color: 'black' }}>{meeting.end_time}</TableCell>
                      <TableCell style={{color: 'black' }}>{meeting.relatedto}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: '20px', maxHeight: '400px', overflow: 'auto', background: 'white', color: 'black' }}>
            <Typography variant="h5">Today's Leads</Typography>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Lead Name</TableCell>
                    <TableCell>Lead Email</TableCell>
                    <TableCell>Contact No</TableCell>
                    <TableCell>Lead State</TableCell>
                    {/* <TableCell>Related To</TableCell>
                    <TableCell>Contact Name</TableCell> */}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leads.map((leads) => (
                    <TableRow key={leads.id} style={{color: 'black' }}>
                      <TableCell style={{color: 'black' }}>{leads.leadname}</TableCell>
                      <TableCell style={{color: 'black' }}>{leads.leademail}</TableCell>
                      <TableCell style={{color: 'black' }}>{leads.contactno}</TableCell>
                      <TableCell style={{color: 'black' }}>{leads.leadstate}</TableCell>
                      {/* <TableCell style={{color: 'black' }}>{leads.leadStatus}</TableCell>
                      <TableCell style={{color: 'black' }}>{leads.contactName}</TableCell> */}
                      <TableCell>
                        <IconButton onClick={() => handleEdit(leads.id)} size="small" style={{color: 'black' }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(leads.id)} size="small" style={{color: 'black' }}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: '20px', maxHeight: '400px', overflow: 'auto', background: 'white', color: 'black' }}>
            <Typography variant="h5">My Deals Closing This Month</Typography>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Related To</TableCell>
                    <TableCell>Contact Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mockMeetings.map((meeting, index) => (
                    <TableRow key={index}>
                      <TableCell style={{color: 'black' }}>{meeting.title}</TableCell>
                      <TableCell style={{color: 'black' }}>{meeting.from}</TableCell>
                      <TableCell style={{color: 'black' }}>{meeting.to}</TableCell>
                      <TableCell style={{color: 'black' }}>{meeting.relatedTo}</TableCell>
                      <TableCell style={{color: 'black' }}>{meeting.contactName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} style={{ padding: '20px', maxHeight: '400px', overflow: 'auto', background: 'white', color: 'black' }}>
            <Typography variant="h5">My Pipeline Deals By Stage</Typography>
            <TableContainer>
            <Table stickyHeader>
            <FunnelChart width={400} height={400}>
              <Tooltip />
              <Funnel dataKey="value" data={pipelineData} isAnimationActive>
                <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
              </Funnel>
            </FunnelChart>
            </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
