import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import '../../App.css';
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "../../utils/axios";
import {
  Box,
  Button,
  TextField,
  Select,
  Switch,
  Typography,
  Paper,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import { useUser } from "../../contexts/auth";
import { useParams } from 'react-router-dom';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));
const validationSchema = yup.object({
  subject: yup.string().required('Subject is required'),
  duedate: yup.date().nullable().required('Due Date is required'),
  contact: yup.string().required('Contact is required'),
  account: yup.string().required('Account is required'),
});

const UpdateTask = () => {
  const { taskid } = useParams();
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
   const initialValues= {
    taskowner: userid || "",
    subject: '',
    duedate: null,
    contact: '',
    account: '',
    statustask: '',
    priority: '',
    description: '',
    companycodes: companycode || "", // Initialize company code
  }
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [reminderEnabled, setReminderEnabled] = useState(false);
  const [repeatEnabled, setRepeatEnabled] = useState(false);
  const [taskOwners, setTaskOwners] = useState([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(companycode || "");
  const [companycodes, setCompanyCodes] = useState([]);
  const [contacts, setContactsId] = useState([]);
  const [accounts, setAccount] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setValues
  } = useFormik({
    initialValues,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companycodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };
        const formDataToSend = new FormData();
        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        console.log(JSON.stringify(leadDataWithUsername));
        const response = await axios.post(`/updatetask/${taskid}`, formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("Task data added successful");
          setOpenSuccess(true);
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const fetchDatas = async () => {
    try {
      const response = await axios.get("/getCompanyRegistrations");
      console.log(response.data);
      const formattedCodes = response.data.map(
        (company) => company.establishmentid
      );
      setCompanyCodes(formattedCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await axios.get(`/gettaxbyid/${taskid}`);
       console.log(response.data)
       const leadData = response.data;
        if (response && response.data) {
          setValues({
            ...leadData,
           
          });
          console.log(response.data)
        } else {
          // Handle error for failed request
        }
      } catch (error) {
        // Handle network errors
      }
    };
    fetchLeadData();
  }, [taskid]);
  const fetchData = async () => {
    try {
      if (selectedCompanyCode) {
        const response = await axios.get("/getUsers", {
          params: {
            companycode: selectedCompanyCode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setTaskOwners(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchContactData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getcontacts", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setContactsId(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };
  const fetchAccountData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getaccounts", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };
 
  useEffect(() => {
    fetchDatas();
    fetchData(); // Fetch data on component mount
    fetchAccountData();
    fetchContactData()
  }, [userrole, companycode, userid, username,]);
  useEffect(() => {
    if (selectedCompanyCode) {
      fetchData(); // Fetch data when selectedCompanyCode changes
    }
  }, [selectedCompanyCode]);
 

  return (
    <>
    <Helmet>
      <title>Create Task - Contacttab.com</title>
    </Helmet>
    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={3} sx={{ p: 3, m: 2 }} style={{marginTop: '6%'}}> */}
        {/* <Typography variant="h5" gutterBottom>
          Create Task
        </Typography> */}
        <form onSubmit={handleSubmit}>
        <Box m="20px">
        <h1>Create Tax</h1>

        <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="taskowner" style={{ marginBottom: '4px' }}>
              Task Owner
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Autocomplete
                    id="taskowner"
                    options={taskOwners}
                    getOptionLabel={(option) => option.firstname || ""}
                    value={taskOwners.find(owner => owner.userid === values.taskowner) || null}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "taskowner",
                          value: newValue ? newValue.userid : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={touched.taskowner && Boolean(errors.taskowner)}
                        helperText={touched.taskowner && errors.taskowner}
                      />
                    )}
                  />
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="subject" style={{ marginBottom: '4px' }}>
              Subject
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              {/* <TextField
                fullWidth
                name="subject"
                value={values.subject}
                onChange={handleChange}
                error={touched.subject && Boolean(errors.subject)}
                helperText={touched.subject && errors.subject}
              /> */}
                       <FormControl fullWidth>
                <Select
                  name="subject"
                  value={values.subject}
                  onChange={handleChange}
                >
                   <MenuItem value="Send Letter">Send Letter</MenuItem>
                  <MenuItem value="Call">Call</MenuItem>
                  <MenuItem value="Email">Email</MenuItem>
                  <MenuItem value="Product Demo">Product Demo</MenuItem>
                  <MenuItem value="Metting">Metting</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="contact" style={{ marginBottom: '4px' }}>
             Contact
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
            <Autocomplete
                    id="contact"
                    options={contacts}
                    getOptionLabel={(option) => option.accountname || ""}
                    value={contacts.find(owner => owner.contactid === values.contact) || null}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "contact",
                          value: newValue ? newValue.contactid : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={touched.taskowner && Boolean(errors.taskowner)}
                        helperText={touched.taskowner && errors.taskowner}
                      />
                    )}
                  />
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="account" style={{ marginBottom: '4px' }}>
              Account
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                    id="account"
                    options={accounts}
                    getOptionLabel={(option) => option.accountname || ""}
                    value={accounts.find(owner => owner.accountid === values.account) || null}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "account",
                          value: newValue ? newValue.accountid : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={touched.taskowner && Boolean(errors.taskowner)}
                        helperText={touched.taskowner && errors.taskowner}
                      />
                    )}
                  />
            </Grid>
            </Grid>
           <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="status" style={{ marginBottom: '4px' }}>
              Status
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Select
                  name="statustask"
                  value={values.statustask}
                  onChange={handleChange}
                >
                  <MenuItem value="Not Started">Not Started</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="priority" style={{ marginBottom: '4px' }}>
              Priority
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Select
                  name="priority"
                  value={values.priority}
                  onChange={handleChange}
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            </Grid>
           <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="duedate" style={{ marginBottom: '4px' }}>
              Due Date
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
                <CustomTextField
                  fullWidth
                  name="duedate"
                  type="date"
                  value={values.duedate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
               
              {/* Company Code Field */}
              <Grid item xs={1.6}>
                <InputLabel htmlFor="companycodes" style={{ marginBottom: '4px' }}>
                  Company Code
                </InputLabel>
              </Grid>
              {userrole === "Superadmin" && companycode === 1 ? (
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={companycodes}
                      getOptionLabel={(option) => option ? option.toString() : ''}
                      onChange={(event, value) => {
                        setSelectedCompanyCode(value ||values.companycodes); // Update selected company code
                        handleChange({
                          target: {
                            name: "companycodes",
                            value: value || values.companycodes, // Ensure it handles the case where no value is selected
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.companycodes && !!errors.companycodes}
                          helperText={touched.companycodes && errors.companycodes ? errors.companycodes : ""}
                        />
                      )}
                      value={values.companycodes} // Controlled value
                      isOptionEqualToValue={(option, value) => option === value} // Check equality
                    />

                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    value={values.companycodes}
                    name="companycodes"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="duedate" style={{ marginBottom: '4px' }}>
              Description
              </InputLabel>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                multiline
                rows={4}
                name="description"
                value={values.description}
                onChange={handleChange}
              />
            </Grid>
            </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}
            >
              Save
            </Button>
            <Button
              type="button"
              onClick={() => {
                resetForm();
                setReminderEnabled(false);
                setRepeatEnabled(false);
              }}
              variant="contained"
              color="primary"
              style={{ margin: '20px', background: 'blue', padding: '5px 10px' }}
            >
              Cancel
            </Button>
          </Box>
          </Box>
        </form>
      {/* </Paper>
    </LocalizationProvider> */}
    <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default UpdateTask;
