import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, InputLabel, FormControlLabel, Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Paper, Button, Grid, Menu, MenuItem, FormControl } from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { mockDataContacts } from "../../../data/mockData";
import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import Header from "../../../assets/charts/Header";
import { useUser } from "../../../contexts/auth";
import { Helmet } from "react-helmet";
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const Alluser = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const [selectedView, setSelectedView] = useState('All Leads');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameFilter, setNameFilter] = useState('All');
  const [anchorEl, setAnchorEl] = useState(null);
  const viewMenuOpen = Boolean(viewAnchorEl);
  const menuOpen = Boolean(anchorEl);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (companycode !== undefined && userrole !== undefined) {
        //const response = await axios.get("/getUsers");
        const response = await axios.get("/getUsers", {
          // headers: {
          //   Authorization: `Bearer ${token}`,//Include the token for Authorization
          // },
          params: {
            companycode:companycode, // Include the companycode in the query parameters
            userrole: userrole, // Include the userrole in the query parameters
          },
        });
        console.log(response.data)
        if (response && response.data) {
          setUsers(response.data);
        }
      } 
      }
      catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchData();
  }, [companycode,userrole]);
  const systemDefinedFilters = [
    "Touched Records",
    "Untouched Records",
    "Record Action",
    "Related Records Action",
    "Locked",
    "Latest Email Status",
    "Activities",
    "Notes",
    "Campaigns",
    "Cadences"
  ];

  const fieldFilters = [
    "Annual Revenue",
    "City",
    "Company",
    "Converted Account",
    "Converted Contact",
    "Converted Deal",
    "Country",
    "Created By",
    "Created Time",
    "Email",
    "Email Opt Out",
    "Fax",
    "First Name",
    "Industry",
    "Last Activity Time",
    "Last Name",
    "Lead Conversion Time",
    "Lead Name",
    "Lead Owner",
    "Lead Source",
    "Lead Status",
    "Mobile",
    "Modified By",
    "Modified Time",
    "No. of Employees",
    "Phone",
    "Rating",
    "Salutation",
    "Secondary Email",
    "Skype ID",
    "State",
    "Street",
    "Tag",
    "Title",
    "Twitter",
    "Unsubscribed Mode",
    "Unsubscribed Time",
    "Website",
    "Zip Code"
  ];

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };
  const filteredSystemDefinedFilters = systemDefinedFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const filteredFieldFilters = fieldFilters.filter((filter) =>
    filter.toLowerCase().includes(searchTerm)
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = (userId) => {
    navigate(`/update-user/${userId}`);
  };

  const handleDelete = (userId) => {
    setDeleteUserId(userId);
    setOpenDialog(true);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
  
  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteUser/${deleteUserId}`);
  
      if (response && response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== deleteUserId)
        );
        setDeleteSuccessMessage("Lead deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);  
        }, 3000);
      } else {
        console.error("Failed to delete lead");
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
  
    setOpenDialog(false);
  };


  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 0.3,minWidth: 140
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 0.3,minWidth: 140,
      cellClassName: "name-column--cell",
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      flex: 0.3,minWidth: 140
    },
    {
      field: "age",
      headerName: "Age",
      flex: 0.3,minWidth: 140
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.3,minWidth: 140
    },
    {
      field: "number",
      headerName: "Phone Number",
      flex: 0.3,minWidth: 140
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.3,minWidth: 140
    },
    {
      field: "joining date",
      headerName: "Joining Date",
      flex: 0.3,minWidth: 140
    },
    {
      field: "address",
      headerName: "Address",
      flex: 0.3,minWidth: 140
    },
    {
      field: "companycode",
      headerName: "Company Code",
      flex: 0.3,minWidth: 140
    },
    {
      headerName: "Actions",
      flex: 0.3,minWidth: 140,
      renderCell: (props) => buttonAlign(props),
    },
  ];

  const buttonAlign = (props) => {
    return (
      <>
        <EditIcon
          style={{ marginRight: "10px" }}
          onClick={() => handleEdit(props.row.id)}
        />
        <DeleteIcon onClick={() => handleDelete(props.row.id)} />
      </>
    );
  };
  return (
    <Box>
       <Helmet>
      <title>Users - Contacttab.com</title>
    </Helmet>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
          <Select
            labelId="lead-select-label"
            value={selectedView}
            onChange={handleChange}
            label="Public Views"
            style={{color: 'white'}}
          >
            <MenuItem value="All Leads">All Users</MenuItem>
            {/* <MenuItem value="All Locked Leads">All Locked Leads</MenuItem>
            <MenuItem value="Converted Leads">Converted Leads</MenuItem> */}
           
          </Select>
        </FormControl>
        <Box>
          <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/add-user')}>Create User</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Convert</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Drafts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Email</MenuItem>
            <MenuItem onClick={handleMenuClose}>Autoresponders</MenuItem>
            <MenuItem onClick={handleMenuClose}>Approve Leads</MenuItem>
            <MenuItem onClick={handleMenuClose}>Deduplicate Leads</MenuItem>
            <MenuItem onClick={handleMenuClose}>Add to Campaigns</MenuItem>
            <MenuItem onClick={handleMenuClose}>Create Client Script</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Leads</MenuItem>
            <MenuItem onClick={handleMenuClose}>Zoho Sheet View</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>
      
        <Grid container columnGap={5}>
        <Grid item xs={2}>
          <Box
            sx={{
              p: 2,
              borderRight: "1px solid #e0e0e0",
              height: "76vh",
              background: "black",
              overflowY: "auto",
              marginTop: "20px"
            }}
          >
            <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter User by</Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              fullWidth
              sx={{ mb: 2 }}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <Typography
              variant="body2"
              color="white"
              sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
              onClick={toggleSystemFilters}
            >
              System Defined Filters <IconButton
                sx={{
                  color: "white",
                  transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {systemFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {filteredSystemDefinedFilters.map((filter) => (
                  <FormControlLabel
                    key={filter}
                    control={<Checkbox />}
                    label={filter}
                    sx={{ color: "white" }}
                  />
                ))}
              </Box>
            )}

            <Typography
              variant="body2"
              color="white"
              sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
              onClick={toggleFieldFilters}
            >
              Filter By Fields <IconButton
                sx={{
                  color: "white",
                  transform: fieldFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Typography>
            {fieldFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {filteredFieldFilters.map((filter) => (
                  <FormControlLabel
                    key={filter}
                    control={<Checkbox />}
                    label={filter}
                    sx={{ color: "white" }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={9.5}>
        <Paper elevation={3} style={{ padding: '20px', height: '76vh', overflow: 'auto', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: </Typography>
            <DataGrid
             rows={users}
             columns={columns}
             components={{ Toolbar: GridToolbar }}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              autoHeight
              style={{ overflowX: 'auto' }}
              sx={{ '& .MuiDataGrid-columnHeaderTitleContainer': { overflow: 'visible' } }}
              disableColumnMenu
            />

            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this lead?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Alluser;
