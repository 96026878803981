import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Select,
  Box,
  Snackbar,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  Autocomplete,
} from "@mui/material";

import axios from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";
// import { useUser } from "../../../Provider";
import '../../../App.css'
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));
const Addlead = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [leadImage, setLeadImage] = useState(null);
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  const navigate = useNavigate();
  const initialValues = {
    leadName: "",
    contactNo: "",
    leadEmail: "",
    leadLocation: "",
    leadSource: "",
    leadComments: "",
    status: "active",
    leadStatus: "None",
    rating: "Active",
    fax: "",
    website: "",
    industry: "None",
    annualRevenue: "",
    twitter: "",
    skypeId: "",
    secondaryEmail: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    leadComments: "",
    companyCodes:companycode ||"",
      // userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize de code
  };
  const [companyCodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        console.log(response.data);
        const formattedCodes = response.data.map(
          (company) => company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const ValidationSchema = yup.object().shape({
    leadName: yup.string().required("Lead name is required"),
    contactNo: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Phone Number is required"),
    leadEmail: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companyCodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };
        const formDataToSend = new FormData();
        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        // console.log(JSON.stringify(formData));
        formDataToSend.append("leadImage", leadImage);
        console.log(leadImage)
        const response = await axios.post("/addLeads", formDataToSend);
        if (response.status === 200) {
          // Update state with success message
          setSuccessMessage("Lead data added successful");
          setOpenSuccess(true);
          // sendMsg();
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("Lead data not added");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setLeadImage(selectedFiles);
      handleChange({
        target: {
          name: "leadimage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Lead - Contacttab.com</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h4>Lead Image</h4>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <input
              type="file"
              accept="image/*"
              id="lead-image-upload"
              name="leadimage"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <label htmlFor="lead-image-upload">
              <Button
                variant="contained"
                component="span"
              >
                Upload Image
              </Button>
            </label>
            {leadImage && (
              <Box sx={{ ml: 2 }}>
                {/* <img src={leadImage} alt="lead" style={{ width: 100, height: 100, objectFit: 'cover' }} /> */}
                {selectedFileNamePan && (
                  <div>Selected file: {selectedFileNamePan}</div>
                )}
              </Box>
            )}
          </Box></Box>
          <h4 style={{ margin: '10px 20px' }}>Lead Information</h4>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="firstName" style={{ marginBottom: '4px' }}>
                Lead Name
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.leadName}
                name="leadName"
                error={touched.leadName && !!errors.leadName} // Show error style if touched and error exists
                helperText={
                  touched.leadName && errors.leadName
                    ? "leadName is required"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={1.6}>
              <InputLabel htmlFor="contactNo" style={{ marginBottom: '4px' }}>
                Phone Number
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.contactNo}
                name="contactNo"
                error={touched.contactNo && !!errors.contactNo} // Show error style if touched and error exists
                helperText={
                  touched.contactNo && errors.contactNo
                    ? "contactNo is required"
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadEmail" style={{ marginBottom: '4px' }}>
                Lead Email
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.leadEmail}
                name="leadEmail"
                error={touched.leadEmail && !!errors.leadEmail} // Show error style if touched and error exists
                helperText={
                  touched.leadEmail && errors.leadEmail
                    ? "leadEmail is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadLocation" style={{ marginBottom: '4px' }}>
                Location
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.leadLocation}
                name="leadLocation"
                // error={!!touched.leadLocation && !!errors.leadLocation}
                // helperText={touched.leadLocation && errors.leadLocation}
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="fax" style={{ marginBottom: '4px' }}>
                Fax
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.fax}
                name="fax"
                // error={!!touched.fax && !!errors.fax}
                // helperText={touched.fax && errors.fax}
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="website" style={{ marginBottom: '4px' }}>
                Website
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.website}
                name="website"
                // error={!!touched.website && !!errors.website}
                // helperText={touched.website && errors.website}
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadSource" style={{ marginBottom: '4px' }}>
                Lead Source
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.leadSource}
                name="leadSource"
                // error={!!touched.leadSource && !!errors.leadSource}
                // helperText={touched.leadSource && errors.leadSource}
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            {/* Company Code Field */}
            <Grid item xs={1.6}>
              <InputLabel htmlFor="companyCodes" style={{ marginBottom: '4px' }}>
                Company Code
              </InputLabel>
            </Grid>
            {userrole === "Superadmin" && companycode === 1 ? (
              <Grid item xs={4}>
                <FormControl fullWidth>
                  {/* <CustomSelect
                    labelId="companyCode-label"
                    id="companyCodes"
                    onChange={handleChange}
                    value={values.companyCodes}
                    name="companyCodes"
                    error={touched.companyCodes && !!errors.companyCodes}
                    helperText={touched.companyCodes && errors.companyCodes ? errors.companyCodes : ""}
                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                  >
                    <MenuItem value="">Select Company Code</MenuItem>
                    {companyCodes.map((code, index) => (
                      <MenuItem key={index} value={code}>
                        {code}
                      </MenuItem>
                    ))}
                  </CustomSelect> */}
                <Autocomplete
    options={companyCodes}
    getOptionLabel={(option) => String(option)} // Convert option to string
    onChange={(event, value) => {
        handleChange({
            target: {
                name: "companyCodes",
                value: value || "", // Ensure it handles the case where no value is selected
            },
        });
    }}
    renderInput={(params) => (
        <TextField
            {...params}
            variant="outlined"
            error={touched.companyCodes && !!errors.companyCodes}
            helperText={touched.companyCodes && errors.companyCodes ? errors.companyCodes : ""}
        />
    )}
    value={values.companyCodes} // Controlled value
    isOptionEqualToValue={(option, value) => option === value} // Check equality
/>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  value={companycode}
                  name="companyCodes"
                  sx={{ gridColumn: "span 2" }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadStatus" style={{ marginBottom: '4px' }}>
              Lead Status
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
              >
                <CustomSelect
                  labelId="leadStatus-label"
                  id="leadStatus"
                  onChange={handleChange}
                  value={values.leadStatus}
                  name="leadStatus"
                  error={touched.leadStatus && !!errors.leadStatus}
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="Attempted to Contact">
                    Attempted to Contact
                  </MenuItem>
                  <MenuItem value="Contact in Future">Contact in Future</MenuItem>
                  <MenuItem value="Contacted">Contacted</MenuItem>
                  <MenuItem value="Junk Lead">Junk Lead</MenuItem>
                  <MenuItem value="Lost Lead">Lost Lead</MenuItem>
                  <MenuItem value="Not Contacted">Not Contacted</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="contactOwner" style={{ marginBottom: '4px' }}>
                Contact Owner
              </InputLabel>
            </Grid>
            <Grid item xs={4}>

              <FormControl
                fullWidth
              >
                <CustomSelect
                  labelId="industry-label"
                  id="industry"
                  onChange={handleChange}
                  value={values.industry}
                  name="industry"
                  error={touched.industry && !!errors.industry}
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="None">-None-</MenuItem>
                  <MenuItem value="ASP (Application Service Provider)">
                    ASP (Application Service Provider)
                  </MenuItem>
                  <MenuItem value="Data/Telecom OEM">Data/Telecom OEM</MenuItem>
                  <MenuItem value="ERP (Enterprise Resource Planning)">ERP (Enterprise Resource Planning)</MenuItem>
                  <MenuItem value="Government/Military">Government/Military</MenuItem>
                  <MenuItem value="Large Enterprise">Large Enterprise</MenuItem>
                  <MenuItem value="ManagementISV">ManagementISV</MenuItem>
                  <MenuItem value="MSP (Management Service Provider)">MSP (Management Service Provider)</MenuItem>
                  <MenuItem value="Network Equipment Enterprise">Network Equipment Enterprise</MenuItem>
                  <MenuItem value="Non-management ISV">Non-management ISV</MenuItem>
                  <MenuItem value="Optical Networking">Optical Networking</MenuItem>
                  <MenuItem value="Service Provider">Service Provider</MenuItem>
                  <MenuItem value="Small/Medium Enterprise">Small/Medium Enterprise</MenuItem>
                  <MenuItem value="Storage Equipment">Storage Equipment</MenuItem>
                  <MenuItem value="Storage Service Provider">Storage Service Provider</MenuItem>
                  <MenuItem value="Systems Integrator">Systems Integrator</MenuItem>
                  <MenuItem value="Wireless Industry">Wireless Industry</MenuItem>
                  <MenuItem value="ERP">ERP</MenuItem>
                  <MenuItem value="Management ISV">Management ISV</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="employeeNumber" style={{ marginBottom: '4px' }}>
              No.of Employees
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.employeeNumber}
                name="employeeNumber"
                error={touched.employeeNumber && !!errors.employeeNumber} // Show error style if touched and error exists
                helperText={
                  touched.employeeNumber && errors.employeeNumber
                    ? "employeeNumber is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="annualRevenue" style={{ marginBottom: '4px' }}>
                Annual Revenue
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth

                type="text"
                onChange={handleChange}
                value={values.annualRevenue}
                name="annualRevenue"
                error={touched.annualRevenue && !!errors.annualRevenue} // Show error style if touched and error exists
                helperText={
                  touched.annualRevenue && errors.annualRevenue
                    ? "annualRevenue is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="rating-label" style={{ marginBottom: '4px' }}>
                Rating
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
              >
                <CustomSelect
                  labelId="rating-label"
                  id="rating"
                  onChange={handleChange}
                  value={values.rating}
                  name="rating"
                  error={touched.rating && !!errors.rating}
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                >
                  <MenuItem value="">-None-</MenuItem>
                  <MenuItem value="Acquired">Acquired</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Market Failed">Market Failed</MenuItem>
                  <MenuItem value="Project Cancelled">Project Cancelled</MenuItem>
                  <MenuItem value="Shut Down">Shut Down</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="skypeId" style={{ marginBottom: '4px' }}>
                Skype ID
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.skypeId}
                name="skypeId"
                error={touched.skypeId && !!errors.skypeId} // Show error style if touched and error exists
                helperText={
                  touched.skypeId && errors.skypeId ? "skypeId is required" : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="secondaryEmail" style={{ marginBottom: '4px' }}>
                Secondary Email
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.secondaryEmail}
                name="secondaryEmail"
                error={touched.secondaryEmail && !!errors.secondaryEmail} // Show error style if touched and error exists
                helperText={
                  touched.secondaryEmail && errors.secondaryEmail ? "secondaryEmail is required" : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="twitter" style={{ marginBottom: '4px' }}>
                Twitter
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
                onChange={handleChange}
                value={values.twitter}
                name="twitter"
                error={touched.twitter && !!errors.twitter} // Show error style if touched and error exists
                helperText={
                  touched.twitter && errors.twitter ? "twitter is required" : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>

        <h4 style={{ margin: '10px 15px' }}>Address Information</h4>
        
         <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="street" style={{ marginBottom: '4px' }}>
              Street
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth

              type="text"
              label="Street"
              onChange={handleChange}
              value={values.street}
              name="street"
              // error={touched.street && !!errors.street} // Show error style if touched and error exists
              // helperText={
              //   touched.street && errors.street
              //     ? "street is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            /></Grid>
              <Grid item xs={1.6}>
              <InputLabel htmlFor="city" style={{ marginBottom: '4px' }}>
              City
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              type="text"
              label="City"
              onChange={handleChange}
              value={values.city}
              name="city"
              // error={touched.city && !!errors.city} // Show error style if touched and error exists
              // helperText={
              //   touched.city && errors.city
              //     ? "city is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            /></Grid></Grid> <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="state" style={{ marginBottom: '4px' }}>
              State
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth

              type="text"
              label="State"
              onChange={handleChange}
              value={values.state}
              name="state"
              // error={touched.leadEmail && !!errors.leadEmail} // Show error style if touched and error exists
              // helperText={
              //   touched.leadEmail && errors.leadEmail
              //     ? "leadEmail is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="zipCode" style={{ marginBottom: '4px' }}>
              ZipCode
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth

              type="text"
              label="Zip Code"
              onChange={handleChange}
              value={values.zipCode}
              name="zipCode"
              // error={!!touched.leadLocation && !!errors.leadLocation}
              // helperText={touched.leadLocation && errors.leadLocation}
              sx={{ gridColumn: "span 2" }}
            /></Grid></Grid> <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="country" style={{ marginBottom: '4px' }}>
              Country
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              type="text"
              label="Country"
              onChange={handleChange}
              value={values.country}
              name="country"
              // error={!!touched.fax && !!errors.fax}
              // helperText={touched.fax && errors.fax}
              sx={{ gridColumn: "span 4" }}
            /></Grid></Grid>

        <h4 style={{ margin: '10px 15px' }}>Description Information</h4>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadComments" style={{ marginBottom: '4px' }}>
              Comment
              </InputLabel>
            </Grid>
          <Grid item xs={10}>
            <CustomTextField
              fullWidth
              multiline
              rows={4}
              onChange={handleChange}
              value={values.leadComments}
              name="leadComments"
              // error={!!touched.leadComments && !!errors.leadComments}
              // helperText={touched.leadComments && errors.leadComments}
              sx={{ gridColumn: "span 4" }}
            />  </Grid></Grid>
          {/* </Box> */}
          <Box display="flex" justifyContent="center" mt="20px" mb="50px">
            <Button type="submit" color="secondary" variant="contained">
              SUBMIT
            </Button>
          </Box>
      </form>
      <br />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default Addlead;
