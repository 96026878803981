import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import API from "../../../utils/axios";
import { useUser } from "../../../contexts/auth";
import Header from "../../../assets/charts/Header";
// import { AuthContext } from "../../../contexts/auth";
// import { useUser } from "../../../Provider";
import '../../../App.css'
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
}));
const LeadDetails = () => {
  const { lead } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [leadimage, setLeadImage] = useState(null);
  const [selectedFileNamePan, setSelectedFileNamePan] = useState("");
  const {
    userrole,
    username,
    userid,
    companycode,
    isAuthRole,
    isAuthorized,
    token,
  } = useUser();

  const navigate = useNavigate();
  const initialValues = {
    leadname: "",
    contactno: "",
    leademail: "",
    leadlocation: "",
    leadsource: "",
    leadcomments: "",
    status: "active",
    leadstatus: "None",
    rating: "Active",
    fax: "",
    website: "",
    industry: "None",
    employeenumber:"",
    annualrevenue: "",
    twitter: "",
    skypeid: "",
    secondaryemail: "",
    street:"",
    city:"",
    state:"",
    zipcode:"",
    country:"",
    leadcomments:"",
    companycodes:
      userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
  };
  const [companycodes, setCompanyCodes] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await axios.get(`/getLeadById/${lead}`);
       console.log(response.data)
       const leadData = response.data;
        if (response && response.data) {
          setValues({
            ...leadData,
           
          });
          console.log(response.data)
        } else {
          // Handle error for failed request
        }
      } catch (error) {
        // Handle network errors
      }
    };

    fetchLeadData();
  }, [lead]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/getCompanyRegistrations");
        console.log(response.data);
        const formattedCodes = response.data.map(
          (company) => company.establishmentid
        );
        setCompanyCodes(formattedCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const ValidationSchema = yup.object().shape({
    leadname: yup.string().required("Lead name is required"),
    contactno: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
      .min(10, "Mobile Number must be at least 10 characters")
      .max(10, "Mobile Number must be at most 10 characters")
      .required("Phone Number is required"),
    leademail: yup
      .string()
      .email("Email must be a valid")
      .required("Please enter the email"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    setValues,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        if (userrole !== "Superadmin" || companycode !== 1) {
          values.companycodes = companycode;
        }
        const leadDataWithUsername = {
          ...values,
          username: username,
        };
        const formDataToSend = new FormData();
        formDataToSend.append("formData", JSON.stringify(leadDataWithUsername));
        console.log(JSON.stringify(leadDataWithUsername));
        formDataToSend.append("leadimage", leadimage);
        console.log(leadimage)
        const response = await axios.post(`/updateLeadById/${lead}`, formDataToSend);

        if (response.status === 201) {
          // Update state with success message
          setSuccessMessage("Lead data added successful");
          setOpenSuccess(true);
          // sendMsg();
          // Hide the success message after 3 seconds (adjust duration as needed)
          setTimeout(() => {
            setOpenSuccess(false);
            setSuccessMessage("Lead data not added");
          }, 3000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);

        // Handle specific error cases
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
      }
    },
  });
  const handleImageChange = (event) => {
    const fileName = event.target.files[0].name;
    setSelectedFileNamePan(fileName);
    const selectedFiles = event.target.files[0];
    if (selectedFiles) {
      // Set the file in the state
      setLeadImage(selectedFiles);
      handleChange({
        target: {
          name: "leadimage",
          value: selectedFiles,
          size: selectedFiles.size,
        },
      });
    }
  };
  return (
    <>
    <Helmet>
      <title>Create Lead - Contacttab.com</title>
    </Helmet>
      <form onSubmit={handleSubmit}>
        <Box m="20px">
          <h4>Lead Image</h4>
          <a href={`${API.defaults.baseURL}/uploadimage/lead/${values.leadimg}`} target="_blank" rel="noopener noreferrer"> 
          <span>{values.leadimg}</span></a>
          <h4>Lead Information</h4>
                    <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="firstName" style={{ marginBottom: '4px' }}>
                Lead Name
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.leadname}
                name="leadname"
                error={touched.leadname && !!errors.leadname} // Show error style if touched and error exists
                helperText={
                  touched.leadname && errors.leadname
                    ? "leadname is required"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={1.6}>
              <InputLabel htmlFor="contactno" style={{ marginBottom: '4px' }}>
                Phone Number
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.contactno}
                name="contactno"
                error={touched.contactno && !!errors.contactno} // Show error style if touched and error exists
                helperText={
                  touched.contactno && errors.contactno
                    ? "contactno is required"
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leademail" style={{ marginBottom: '4px' }}>
                Lead Email
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.leademail}
                name="leademail"
                error={touched.leademail && !!errors.leademail} // Show error style if touched and error exists
                helperText={
                  touched.leademail && errors.leademail
                    ? "leademail is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadlocation" style={{ marginBottom: '4px' }}>
                Location
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.leadlocation}
                name="leadlocation"
                // error={!!touched.leadlocation && !!errors.leadlocation}
                // helperText={touched.leadlocation && errors.leadlocation}
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="fax" style={{ marginBottom: '4px' }}>
                Fax
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.fax}
                name="fax"
                // error={!!touched.fax && !!errors.fax}
                // helperText={touched.fax && errors.fax}
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="website" style={{ marginBottom: '4px' }}>
                Website
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.website}
                name="website"
                // error={!!touched.website && !!errors.website}
                // helperText={touched.website && errors.website}
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadsource" style={{ marginBottom: '4px' }}>
                Lead Source
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.leadsource}
                name="leadsource"
                // error={!!touched.leadsource && !!errors.leadsource}
                // helperText={touched.leadsource && errors.leadsource}
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            {/* Company Code Field */}
            <Grid item xs={1.6}>
              <InputLabel htmlFor="companycodes" style={{ marginBottom: '4px' }}>
                Company Code
              </InputLabel>
            </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="text"
                  value={values.companycode}
                  name="companycodes"
                  sx={{ gridColumn: "span 2" }}
                />
              </Grid>
         
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadstatus" style={{ marginBottom: '4px' }}>
              Lead Status
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
              >
                <CustomTextField
                  labelId="leadstatus-label"
                  id="leadstatus"
                 
                  value={values.leadstatus}
                  name="leadstatus"
                  error={touched.leadstatus && !!errors.leadstatus}
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="contactowner" style={{ marginBottom: '4px' }}>
              Industry
              </InputLabel>
            </Grid>
            <Grid item xs={4}>

              <FormControl
                fullWidth
              >
                <CustomTextField
                  labelId="industry-label"
                  id="industry"
                 
                  value={values.industry}
                  name="industry"
                  error={touched.industry && !!errors.industry}
                
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
               />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="employeenumber" style={{ marginBottom: '4px' }}>
              No.of Employees
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.employeenumber}
                name="employeenumber"
                error={touched.employeenumber && !!errors.employeenumber} // Show error style if touched and error exists
                helperText={
                  touched.employeenumber && errors.employeenumber
                    ? "employeenumber is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="annualrevenue" style={{ marginBottom: '4px' }}>
                Annual Revenue
              </InputLabel>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                fullWidth

                type="text"
               
                value={values.annualrevenue}
                name="annualrevenue"
                error={touched.annualrevenue && !!errors.annualrevenue} // Show error style if touched and error exists
                helperText={
                  touched.annualrevenue && errors.annualrevenue
                    ? "annualrevenue is required"
                    : ""
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="rating-label" style={{ marginBottom: '4px' }}>
                Rating
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
              >
                <CustomTextField
                  labelId="rating-label"
                  id="rating"
                 
                  value={values.rating}
                  name="rating"
                  error={touched.rating && !!errors.rating}
                 
                  MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
               />
                 
              </FormControl>
            </Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="skypeid" style={{ marginBottom: '4px' }}>
                Skype ID
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.skypeid}
                name="skypeid"
                error={touched.skypeid && !!errors.skypeid} // Show error style if touched and error exists
                helperText={
                  touched.skypeid && errors.skypeid ? "skypeid is required" : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="secondaryemail" style={{ marginBottom: '4px' }}>
                Secondary Email
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.secondaryemail}
                name="secondaryemail"
                error={touched.secondaryemail && !!errors.secondaryemail} // Show error style if touched and error exists
                helperText={
                  touched.secondaryemail && errors.secondaryemail ? "secondaryemail is required" : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="twitter" style={{ marginBottom: '4px' }}>
                Twitter
              </InputLabel>
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                fullWidth
                type="text"
               
                value={values.twitter}
                name="twitter"
                error={touched.twitter && !!errors.twitter} // Show error style if touched and error exists
                helperText={
                  touched.twitter && errors.twitter ? "twitter is required" : ""
                }
                sx={{ gridColumn: "span 2" }}
              /></Grid></Grid>

        <h4 style={{ margin: '10px 10px' }}>Address Information</h4>
        
         <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="street" style={{ marginBottom: '4px' }}>
              Street
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              type="text"
             
              value={values.street}
              name="street"
              // error={touched.street && !!errors.street} // Show error style if touched and error exists
              // helperText={
              //   touched.street && errors.street
              //     ? "street is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            /></Grid>
              <Grid item xs={1.6}>
              <InputLabel htmlFor="city" style={{ marginBottom: '4px' }}>
              City
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              type="text"
             
              value={values.city}
              name="city"
              // error={touched.city && !!errors.city} // Show error style if touched and error exists
              // helperText={
              //   touched.city && errors.city
              //     ? "city is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            /></Grid></Grid> <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="state" style={{ marginBottom: '4px' }}>
              State
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              type="text"
             
              value={values.state}
              name="state"
              // error={touched.leademail && !!errors.leademail} // Show error style if touched and error exists
              // helperText={
              //   touched.leademail && errors.leademail
              //     ? "leademail is required"
              //     : ""
              // }
              sx={{ gridColumn: "span 2" }}
            /></Grid>
            <Grid item xs={1.6}>
              <InputLabel htmlFor="zipcode" style={{ marginBottom: '4px' }}>
              ZipCode
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              type="text"
             
              value={values.zipcode}
              name="zipcode"
              // error={!!touched.leadlocation && !!errors.leadlocation}
              // helperText={touched.leadlocation && errors.leadlocation}
              sx={{ gridColumn: "span 2" }}
            /></Grid></Grid> <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="country" style={{ marginBottom: '4px' }}>
              Country
              </InputLabel>
            </Grid>
          <Grid item xs={4}>
            <CustomTextField
              fullWidth
              type="text"
             
              value={values.country}
              name="country"
              // error={!!touched.fax && !!errors.fax}
              // helperText={touched.fax && errors.fax}
              sx={{ gridColumn: "span 4" }}
            /></Grid></Grid>

        <h4 style={{ margin: '10px 10px' }}>Description Information</h4>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={1.6}>
              <InputLabel htmlFor="leadcomments" style={{ marginBottom: '4px' }}>
              Comment
              </InputLabel>
            </Grid>
          <Grid item xs={10}>
            <CustomTextField
              fullWidth
              multiline
              rows={4}
             
              value={values.leadcomments}
              name="leadcomments"
              // error={!!touched.leadcomments && !!errors.leadcomments}
              // helperText={touched.leadcomments && errors.leadcomments}
              sx={{ gridColumn: "span 4" }}
            />  </Grid></Grid>
        </Box>
      </form>
      <br/>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSuccess}
        autoHideDuration={3000}
        // onClose={handleSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default LeadDetails;



// import { Formik } from "formik";
// import * as yup from "yup";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { Helmet } from 'react-helmet-async';
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";

// import {
//   TextField,
//   Button,
//   Grid,
//   Typography,
//   Divider,
//   Box,
//   Snackbar,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import { useParams } from "react-router-dom";
// import axios from "../../../utils/axios";
// import { useUser } from "../../../contexts/auth";
// import Header from "../../../assets/charts/Header";
// // import { AuthContext } from "../../../contexts/auth";
// // import { useUser } from "../../../Provider";
// import '../../../App.css'

// const LeadDetails = () => {
//   const { lead } = useParams();
//   const isNonMobile = useMediaQuery("(min-width:600px)");
//   const [leadImage, setLeadImage] = useState(null);
//   const {
//     userrole,
//     username,
//     userid,
//     companycode,
//     isAuthRole,
//     isAuthorized,
//     token,
//   } = useUser();

//   const navigate = useNavigate();
//   const initialValues = {
//     leadname: "",
//     contactno: "",
//     leademail: "",
//     leadlocation: "",
//     leadsource: "",
//     leadcomments: "",
//     status: "active",
//     leadstatus: "None",
//     rating: "Active",
//     fax: "",
//     website: "",
//     industry: "None",
//     employeenumber:"",
//     annualrevenue: "",
//     twitter: "",
//     skypeid: "",
//     secondaryemail: "",
//     street:"",
//     city:"",
//     state:"",
//     zipcode:"",
//     country:"",
//     leadcomments:"",
//     companyCodes:
//       userrole === "Superadmin" && companycode === 1 ? "" : companycode, // Initialize company code
//   };
//   const [companyCodes, setCompanyCodes] = useState([]);
//   const [successMessage, setSuccessMessage] = useState("");
//   const [openSuccess, setOpenSuccess] = useState(false);
//   useEffect(() => {
//     const fetchLeadData = async () => {
//       try {
//         const response = await axios.get(`/getLeadById/${lead}`);
//        console.log(response.data)
//        const leadData = response.data;
//         if (response && response.data) {
//           setValues({
//             ...leadData,
           
//           });
//           console.log(response.data)
//         } else {
//           // Handle error for failed request
//         }
//       } catch (error) {
//         // Handle network errors
//       }
//     };

//     fetchLeadData();
//   }, [lead]);


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get("/getCompanyRegistrations");
//         console.log(response.data);
//         const formattedCodes = response.data.map(
//           (company) => company.establishmentid
//         );
//         setCompanyCodes(formattedCodes);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const ValidationSchema = yup.object().shape({
//     leadname: yup.string().required("Lead name is required"),
//     contactno: yup
//       .string()
//       .matches(/^[0-9]+$/, "Mobile Number should only contain numbers")
//       .min(10, "Mobile Number must be at least 10 characters")
//       .max(10, "Mobile Number must be at most 10 characters")
//       .required("Phone Number is required"),
//     leademail: yup
//       .string()
//       .email("Email must be a valid")
//       .required("Please enter the email"),
//   });

//   const {
//     values,
//     errors,
//     touched,
//     handleBlur,
//     setValues,
//     handleChange,
//     handleReset,
//     handleSubmit,
//   } = useFormik({
//     initialValues,
//     validationSchema: ValidationSchema,
//     onSubmit: async () => {
//       try {
//         if (userrole !== "Superadmin" || companycode !== 1) {
//           values.companyCodes = companycode;
//         }
//         const leadDataWithUsername = {
//           ...values,
//           username: username,
//         };

//         const response = await axios.post(`/updateLeadById/${lead}`, leadDataWithUsername);

//         if (response.status === 201) {
//           // Update state with success message
//           setSuccessMessage("Lead data added successful");
//           setOpenSuccess(true);
//           // sendMsg();
//           // Hide the success message after 3 seconds (adjust duration as needed)
//           setTimeout(() => {
//             setOpenSuccess(false);
//             setSuccessMessage("Lead data not added");
//           }, 3000);
//         }
//       } catch (error) {
//         console.error("Error submitting form:", error);

//         // Handle specific error cases
//         if (error.response) {
//           console.error("Response data:", error.response.data);
//           console.error("Response status:", error.response.status);
//         }
//       }
//     },
//   });

//   const handleImageChange = (event) => {
//     if (event.target.files && event.target.files[0]) {
//       setLeadImage(URL.createObjectURL(event.target.files[0]));
//     }
//   };

//   return (
//     <>
//     <Helmet>
//       <title>Create Lead - Contacttab.com</title>
//     </Helmet>
//       <form onSubmit={handleSubmit}>
//       {/* <Box display="flex" alignItem="center" justifyContent="space-between" position="fixed" bgcolor="white" style={{ width: '100%', zIndex: 1000, padding: '10px 20px', }}>
//           <Header style={{color: 'black'}} title="Create Lead" />
//           <Box>
//             <Button type="submit" color="secondary" variant="contained" style={{ marginLeft: '10px' }}>
//               SUBMIT
//             </Button>
//             <Button type="button" color="secondary" variant="contained" style={{ marginLeft: '10px' }}>
//               Cancel
//             </Button>
//           </Box>
//         </Box> */}
//         <Box m="20px">
//           <h4>Lead Image</h4>
//         <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//           <input
//             accept="image/*"
//             id="lead-image-upload"
//             type="file"
//             style={{ display: 'none' }}
//             onChange={handleImageChange}
//           />
//           <label htmlFor="lead-image-upload">
//             <Button
//               variant="contained"
//               component="span"
//             >
//               Upload Image
//             </Button>
//           </label>
//           {leadImage && (
//             <Box sx={{ ml: 2 }}>
//               <img src={leadImage} alt="lead" style={{ width: 100, height: 100, objectFit: 'cover' }} />
//             </Box>
//           )}
//         </Box>
//           <h4>Lead Information</h4>
//           <Box
//             display="grid"
//             gap="30px"
//             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//             sx={{
//               "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//             }}
//           >
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Name"
//               //
//               value={values.leadname}
//               name="leadname"
//               error={touched.leadname && !!errors.leadname} // Show error style if touched and error exists
//               helperText={
//                 touched.leadname && errors.leadname
//                   ? "leadname is required"
//                   : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Phone Number"
//               //
//               value={values.contactno}
//               name="contactno"
//               error={touched.contactno && !!errors.contactno} // Show error style if touched and error exists
//               helperText={
//                 touched.contactno && errors.contactno
//                   ? "contactno is required"
//                   : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Email"
//               //
//               value={values.leademail}
//               name="leademail"
//               error={touched.leademail && !!errors.leademail} // Show error style if touched and error exists
//               helperText={
//                 touched.leademail && errors.leademail
//                   ? "leademail is required"
//                   : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Location"
//               //
//               value={values.leadlocation}
//               name="leadlocation"
//               // error={!!touched.leadlocation && !!errors.leadlocation}
//               // helperText={touched.leadlocation && errors.leadlocation}
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Fax"
//               //
//               value={values.fax}
//               name="fax"
//               // error={!!touched.fax && !!errors.fax}
//               // helperText={touched.fax && errors.fax}
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Website"
//               //
//               value={values.website}
//               name="website"
//               // error={!!touched.website && !!errors.website}
//               // helperText={touched.website && errors.website}
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Lead Source"
//               //
//               value={values.leadsource}
//               name="leadsource"
//               // error={!!touched.leadsource && !!errors.leadsource}
//               // helperText={touched.leadsource && errors.leadsource}
//               sx={{ gridColumn: "span 2" }}
//             />
//              <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Companycode"
//               value={values.companycode}
//               name="companycode"
//               // error={!!touched.leadsource && !!errors.leadsource}
//               // helperText={touched.leadsource && errors.leadsource}
//               sx={{ gridColumn: "span 2" }}
//             />

//             <FormControl
//               fullWidth
//               variant="filled"
//               sx={{ gridColumn: "span 2" }}
//             >
//               <InputLabel id="leadstatus-label">Lead Status</InputLabel>
//               <Select
//                 labelId="leadstatus-label"
//                 id="leadstatus"
//                 //
//                 value={values.leadstatus}
//                 name="leadstatus"
//                 error={touched.leadstatus && !!errors.leadstatus}
//                 label="Lead Status"
//                 MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
//               >
//                 <MenuItem value="None">None</MenuItem>
//                 <MenuItem value="Attempted to Contact">
//                   Attempted to Contact
//                 </MenuItem>
//                 <MenuItem value="Contact in Future">Contact in Future</MenuItem>
//                 <MenuItem value="Contacted">Contacted</MenuItem>
//                 <MenuItem value="Junk Lead">Junk Lead</MenuItem>
//                 <MenuItem value="Lost Lead">Lost Lead</MenuItem>
//                 <MenuItem value="Not Contacted">Not Contacted</MenuItem>
//               </Select>
//             </FormControl>


//             <FormControl
//               fullWidth
//               variant="filled"
//               sx={{ gridColumn: "span 2" }}
//             >
//               <InputLabel id="leadstatus-label">Industry</InputLabel>
//               <Select
//                 labelId="industry-label"
//                 id="industry"
//                 //
//                 value={values.industry}
//                 name="industry"
//                 error={touched.industry && !!errors.industry}
//                 label="Industry"
//                 MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
//               >
//                  <MenuItem value="None">-None-</MenuItem>
//                 <MenuItem value="ASP (Application Service Provider)">
//                 ASP (Application Service Provider)
//                 </MenuItem>
//                 <MenuItem value="Data/Telecom OEM">Data/Telecom OEM</MenuItem>
//                 <MenuItem value="ERP (Enterprise Resource Planning)">ERP (Enterprise Resource Planning)</MenuItem>
//                 <MenuItem value="Government/Military">Government/Military</MenuItem>
//                 <MenuItem value="Large Enterprise">Large Enterprise</MenuItem>
//                 <MenuItem value="ManagementISV">ManagementISV</MenuItem>
//                 <MenuItem value="MSP (Management Service Provider)">MSP (Management Service Provider)</MenuItem>
//                 <MenuItem value="Network Equipment Enterprise">Network Equipment Enterprise</MenuItem>
//                 <MenuItem value="Non-management ISV">Non-management ISV</MenuItem>
//                 <MenuItem value="Optical Networking">Optical Networking</MenuItem>
//                 <MenuItem value="Service Provider">Service Provider</MenuItem>
//                 <MenuItem value="Small/Medium Enterprise">Small/Medium Enterprise</MenuItem>
//                 <MenuItem value="Storage Equipment">Storage Equipment</MenuItem>
//                 <MenuItem value="Storage Service Provider">Storage Service Provider</MenuItem>
//                 <MenuItem value="Systems Integrator">Systems Integrator</MenuItem>
//                 <MenuItem value="Wireless Industry">Wireless Industry</MenuItem>
//                 <MenuItem value="ERP">ERP</MenuItem>
//                 <MenuItem value="Management ISV">Management ISV</MenuItem>
//               </Select>
//             </FormControl>

//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="No. of Employees"
//               //
//               value={values.employeenumber}
//               name="employeenumber"
//               error={touched.employeenumber && !!errors.employeenumber} // Show error style if touched and error exists
//               helperText={
//                 touched.employeenumber && errors.employeenumber
//                   ? "employeenumber is required"
//                   : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />

// <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Annual Revenue"
//               //
//               value={values.annualrevenue}
//               name="annualrevenue"
//               error={touched.annualrevenue && !!errors.annualrevenue} // Show error style if touched and error exists
//               helperText={
//                 touched.annualrevenue && errors.annualrevenue
//                   ? "annualrevenue is required"
//                   : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />

            
//             <FormControl
//               fullWidth
//               variant="filled"
//               sx={{ gridColumn: "span 2" }}
//             >
//               <InputLabel id="rating-label">Rating</InputLabel>
//               <Select
//                 labelId="rating-label"
//                 id="rating"
//                 //
//                 value={values.rating}
//                 name="rating"
//                 error={touched.rating && !!errors.rating}
//                 label="Rating"
//                 MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
//               >
//                 <MenuItem value="">-None-</MenuItem>
//                 <MenuItem value="Acquired">Acquired</MenuItem>
//                 <MenuItem value="Active">Active</MenuItem>
//                 <MenuItem value="Market Failed">Market Failed</MenuItem>
//                 <MenuItem value="Project Cancelled">Project Cancelled</MenuItem>
//                 <MenuItem value="Shut Down">Shut Down</MenuItem>
//               </Select>
//             </FormControl>

//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Skype ID"
//               //
//               value={values.skypeid}
//               name="skypeid"
//               error={touched.skypeid && !!errors.skypeid} // Show error style if touched and error exists
//               helperText={
//                 touched.skypeid && errors.skypeid ? "skypeid is required" : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Secondary Email"
//               //
//               value={values.secondaryemail}
//               name="secondaryemail"
//               error={touched.secondaryemail && !!errors.secondaryemail} // Show error style if touched and error exists
//               helperText={
//                 touched.secondaryemail && errors.secondaryemail ? "secondaryemail is required" : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />
//              <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Twitter"
//               //
//               value={values.twitter}
//               name="twitter"
//               error={touched.twitter && !!errors.twitter} // Show error style if touched and error exists
//               helperText={
//                 touched.twitter && errors.twitter ? "twitter is required" : ""
//               }
//               sx={{ gridColumn: "span 2" }}
//             />
//           </Box>

//           <h4 style={{margin: '30px 0px'}}>Address Information</h4>
//           <Box
//             display="grid"
//             gap="30px"
//             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//             sx={{
//               "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//             }}
//           >
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Street"
//               //
//               value={values.street}
//               name="street"
//               // error={touched.street && !!errors.street} // Show error style if touched and error exists
//               // helperText={
//               //   touched.street && errors.street
//               //     ? "street is required"
//               //     : ""
//               // }
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="City"
//               //
//               value={values.city}
//               name="city"
//               // error={touched.city && !!errors.city} // Show error style if touched and error exists
//               // helperText={
//               //   touched.city && errors.city
//               //     ? "city is required"
//               //     : ""
//               // }
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="State"
//               //
//               value={values.state}
//               name="state"
//               // error={touched.leademail && !!errors.leademail} // Show error style if touched and error exists
//               // helperText={
//               //   touched.leademail && errors.leademail
//               //     ? "leademail is required"
//               //     : ""
//               // }
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Zip Code"
//               //
//               value={values.zipcode}
//               name="zipcode"
//               // error={!!touched.leadlocation && !!errors.leadlocation}
//               // helperText={touched.leadlocation && errors.leadlocation}
//               sx={{ gridColumn: "span 2" }}
//             />
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Country"
//               //
//               value={values.country}
//               name="country"
//               // error={!!touched.fax && !!errors.fax}
//               // helperText={touched.fax && errors.fax}
//               sx={{ gridColumn: "span 4" }}
//             />

//           </Box>

//           <h4 style={{margin: '30px 0px'}}>Description Information</h4>
//           <Box
//             display="grid"
//             gap="30px"
//             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//             sx={{
//               "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//             }}
//           >
//             <TextField
//               fullWidth
//               variant="filled"
//               type="text"
//               label="Comment"
//               //
//               value={values.leadcomments}
//               name="leadcomments"
//               // error={!!touched.leadcomments && !!errors.leadcomments}
//               // helperText={touched.leadcomments && errors.leadcomments}
//               sx={{ gridColumn: "span 4" }}
//             />  
//           </Box>
//           <Box display="flex" justifyContent="end" mt="20px" mb="50px">
//             {/* <Button type="submit" color="secondary" variant="contained">
//               SUBMIT
//             </Button> */}
//           </Box>
//         </Box>
//       </form>
//       <br/>
//       <Snackbar
//         anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//         open={openSuccess}
//         autoHideDuration={3000}
//         // onClose={handleSuccessClose}
//         message={successMessage}
//       />
//     </>
//   );
// };

// export default LeadDetails;
