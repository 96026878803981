import React, { useState, useEffect } from "react";
import { Box, Typography, Radio, IconButton, InputLabel,FormControlLabel,
   Checkbox, Select, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
    Paper, Button, Grid, Menu, MenuItem, FormControl,RadioGroup,FormLabel } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Helmet } from 'react-helmet-async';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../utils/axios";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../assets/charts/Header";
import { useUser } from "../../contexts/auth";
import { useTheme } from "@mui/material/styles";
import '../../App.css'
import ViewListIcon from '@mui/icons-material/ViewList'; // Import the List View icon
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import GridViewIcon from '@mui/icons-material/GridView';
import TableViewIcon from '@mui/icons-material/TableView';
import TocIcon from '@mui/icons-material/Toc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const AllAccount = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [accounts, setAccount] = useState([]);
  // const [deletecontactId, setDeleteContactId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteaccountId, setDeleteLeadId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const { userrole, companycode } = useUser();
  const [selectedView, setSelectedView] = useState('All Accounts');
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
 const [systemFiltersVisible, setSystemFiltersVisible] = useState(true);
  const [fieldFiltersVisible, setFieldFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('All');
  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [fieldSearchTerms, setFieldSearchTerms] = useState({});
  const fetchData = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getaccounts", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };
  const fetchtodayaccount = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/gettodayaccount", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching contact:", error);
    }
  };
  const fetchthisweekaccount = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getthisweekaccount", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching contact:", error);
    }
  };

  const fetchlastweekaccount = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getlastweekaccount", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching account:", error);
    }
  };

  const fetchrecentaddedaccount = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getaddedaccount", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching account:", error);
    }
  };

  const fetchrecentmodifiedaccount = async () => {
    try {
      if (companycode !== undefined && userrole !== undefined) {
        const response = await axios.get("/getmodifiedaccount", {
          params: {
            companycode: companycode,
            userrole: userrole,
          },
        });
        if (response && response.data) {
          setAccount(response.data); // Update junk leads based on the response
        }
      }
    } catch (error) {
      console.error("Error fetching account:", error);
    }
  };
  useEffect(() => {
    if (selectedView === "Today's Account") {
      fetchtodayaccount(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "New This Week") {
      fetchthisweekaccount(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "New Last Week") {
      fetchlastweekaccount(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "Recently Created Accounts") {
      fetchrecentaddedaccount(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else if (selectedView === "Recently Modified Accounts") {
      fetchrecentmodifiedaccount(); // Fetch junk leads if "Junk Leads" is selected
    } 
    else {
      fetchData(); // Fetch other leads based on selected view
    }
  }, [companycode, userrole, selectedView]); // Dependency array includes selectedView
  useEffect(() => {
    setFilteredAccounts(
      accounts.filter(account => {
        if (nameFilter === 'All') return true;
        return account.accountName && account.accountName.charAt(0).toUpperCase() === nameFilter;
      })
    );
  }, [nameFilter, accounts]);
  const [systemDefinedFilters, setSystemDefinedFilters] = useState({
    touchedRecords: { enabled: false, by: 'User & System', timeRange: '2 days' },
    untouchedRecords: { enabled: false, by: 'User & System', timeRange: '2 days' },
    recordAction: { enabled: false, actionType: 'Modified', by: 'User & System', timeRange: '2 days' },
    relatedRecordsAction: { enabled: false, actionType: 'Done', by: 'User & System', timeRange: '2 days' },
    locked: { enabled: false }, // Adding "Locked" as requested
    deals: { enabled: false },
    dealAmount: { enabled: false },
    dealStage: { enabled: false },
    dealOwner: { enabled: false },
    dealClosingDate: { enabled: false },
    contacts: { enabled: false } // Adding "Contacts" as requested
  });
  

  const fieldFilters = [
    "Account Name",
    "Account Number",
    "Account Owner",
    "Account Site",
    "Account Type",
    "Annual Revenue",
    "Billing City",
    "Billing Code",
    "Billing Country",
    "Billing State",
    "Billing Street",
    "Created By",
    "Created Time",
    "Employees",
    "Fax",
    "Industry",
    "Last Activity Time-",
    "Modified By",
    "Modified Time",
    "Ownership",
    "Parent Account",
    "Phone",
    "Rating",
    "Shipping City",
    "Shipping Code",
    "Shipping Country",
    "Shipping State",
    "Shipping Street",
    "SIC Code",
    "Tag",
    "Ticker Symbol",
    "Website",
  ];

  const [filteredFieldFilters, setFilteredFieldFilters] = useState(fieldFilters);
  const handleDelete = (accountId) => {
    setDeleteLeadId(accountId);
    setOpenDialog(true);
  };
  const handleEdit = (accountid) => {
    navigate(`/updateaccount/${accountid}`);
  };
  const toggleSystemFilters = () => {
    setSystemFiltersVisible(!systemFiltersVisible);
  };

  const toggleFieldFilters = () => {
    setFieldFiltersVisible(!fieldFiltersVisible);
  };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value.toLowerCase());
  // };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase(); // Get the new search term
    setSearchTerm(newSearchTerm); // Update the search term
  
    // Filter the fieldFilters using the new search term
    const filteredFieldFilters = fieldFilters.filter((filter) =>
        filter.toLowerCase().includes(newSearchTerm) // Use newSearchTerm for filtering
    );
  
    // Update the state with filtered field filters
    setFilteredFieldFilters(filteredFieldFilters); // Update the filtered results state
    console.log(filteredFieldFilters, "-----"); // Check the filtered results
  };
  const handleViewMenuOpen = (event) => {
    setViewAnchorEl(event.currentTarget);
  };
  const handleChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleViewMenuClose = () => {
    setViewAnchorEl(null);
  };

  const viewMenuOpen = Boolean(viewAnchorEl);
  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };
const columns = [
    { 
      field: "accountname", 
      headerName: "Account Name", 
      minWidth: 160,flex: 0.3,
      renderCell: (params) => (
        <NavLink to={`/accountdetails/${params.row.accountid}`}>
          {params.row.accountname}
        </NavLink>
      )
    },
    { field: "phone", headerName: "Phone", minWidth: 160,flex: 0.3 },
    { field: "website", headerName: "Website", minWidth: 160,flex: 0.3 },
    { field: "firstname", headerName: "Account Owner", minWidth: 160,flex: 0.3 },
    { field: "accountsite", headerName: "Account Site", minWidth: 160,flex: 0.3 },
    {
      headerName: "Actions",
      minWidth: 120,flex: 0.3,
      renderCell: (props) => (
        <>
          <EditIcon
            style={{ marginRight: "10px" }}
            onClick={() => handleEdit(props.row.accountid)}
          />
          <DeleteIcon onClick={() => handleDelete(props.row.accountid)} />
        </>
      ),
    },
    { field: "parentaccount", headerName: "Parent Account", minWidth: 160,flex: 0.3 },
    { field: "accountnumber", headerName: "Account Number", minWidth: 160,flex: 0.3 },
    { field: "accounttype", headerName: "Account Type", minWidth: 160,flex: 0.3 },
    { field: "industry", headerName: "Industry", minWidth: 160,flex: 0.3 },
    { field: "annualrevenue", headerName: "Annual Revenue", minWidth: 160,flex: 0.3 },
    { field: "rating", headerName: "Rating", minWidth: 160,flex: 0.3 },
    { field: "phone", headerName: "Phone No", minWidth: 160,flex: 0.3 },
    { field: "fax", headerName: "Fax", minWidth: 160,flex: 0.3 },
    { field: "website", headerName: "Website", minWidth: 160,flex: 0.3 },
    { field: "tickersymbol", headerName: "Ticker Symbol", minWidth: 160,flex: 0.3 },
    { field: "ownership", headerName: "Ownership", minWidth: 160,flex: 0.3 },
    { field: "employees", headerName: "Employees", minWidth: 160,flex: 0.3 },
    { field: "siccode", headerName: "Siccode", minWidth: 160,flex: 0.3 },
    { field: "billingstreet", headerName: "Billing Street", minWidth: 160,flex: 0.3 },
    { field: "billingstate", headerName: "billing State", minWidth: 160,flex: 0.3 },
    { field: "billingcity", headerName: "Billing City", minWidth: 160,flex: 0.3 },
    { field: "billingstate", headerName: "Billing State", minWidth: 160,flex: 0.3 },
    { field: "billingcode", headerName: "Billing Code", minWidth: 160,flex: 0.3 },
    { field: "billingcountry", headerName: "Billing Country", minWidth: 160,flex: 0.3 },
    { field: "companycode", headerName: "Companycode", minWidth: 160,flex: 0.3 },
  ];

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

 const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/deleteaccount/${deleteaccountId}`);
      if (response && response.status === 200) {
        setAccount((prevLeads) =>
          prevLeads.filter((accounts) => accounts.accountid !== deleteaccountId)
        );
        setDeleteSuccessMessage("Accounts deleted successfully!");
        setTimeout(() => {
          setDeleteSuccessMessage(null);
        }, 3000);
      } else {
        console.error("Failed to delete accounts");
      }
    } catch (error) {
      console.error("Error deleting accounts:", error);
    }
    setOpenDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);
  const [selectedSystemFilters, setSelectedSystemFilters] = useState([]);
  const [selectedFieldFilters, setSelectedFieldFilters] = useState([]);
  // Handle system filter checkbox selection
const handleSystemFilterChange = (filterKey, field, value) => {
  setSystemDefinedFilters((prevState) => ({
    ...prevState,
    [filterKey]: {
      ...prevState[filterKey],
      [field]: value,
    },
  }));
};
const handleFieldSearchChange = (event, filterName) => {
  console.log("----------------")
  setFieldSearchTerms((prev) => ({
    ...prev,
    [filterName]: event.target.value
  }));
};
// Handle field filter checkbox selection
const handleFieldFilterChange = (event) => {
  const { name, checked } = event.target;
  if (checked) {
    setSelectedFieldFilters((prev) => [...prev, name]);
    setFieldSearchTerms((prev) => ({ ...prev, [name]: "" }));
  } else {
    setSelectedFieldFilters((prev) => prev.filter((filter) => filter !== name));
    setFieldSearchTerms((prev) => {
      const { [name]: removed, ...rest } = prev;
      return rest;
    });
  }
};
  useEffect(() => {
    let updatedaccounts = accounts;
  
   // Apply Touched Records Filter
if (systemDefinedFilters.touchedRecords.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add your filtering logic for touched records.
  });
}

// Apply Untouched Records Filter
if (systemDefinedFilters.untouchedRecords.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add your filtering logic for untouched records.
  });
}

// Apply Record Action Filter
if (systemDefinedFilters.recordAction.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add your filtering logic based on record action.
  });
}

// Apply Related Records Action Filter
if (systemDefinedFilters.relatedRecordsAction.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for related records action.
  });
}

// Apply Locked Filter
if (systemDefinedFilters.locked.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for locked records filter.
  });
}

// Apply Deals Filter
if (systemDefinedFilters.deals.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for deals filter.
  });
}

// Apply Deal Amount Filter
if (systemDefinedFilters.dealAmount.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for deal amount filter.
  });
}

// Apply Deal Stage Filter
if (systemDefinedFilters.dealStage.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for deal stage filter.
  });
}

// Apply Deal Owner Filter
if (systemDefinedFilters.dealOwner.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for deal owner filter.
  });
}

// Apply Deal Closing Date Filter
if (systemDefinedFilters.dealClosingDate.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for deal closing date filter.
  });
}

// Apply Contacts Filter
if (systemDefinedFilters.contacts.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for contacts filter.
  });
}

// Apply Latest Email Status Filter
if (systemDefinedFilters.latestEmailStatus && systemDefinedFilters.latestEmailStatus.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for latest email status filter.
  });
}

// Apply Activities Filter
if (systemDefinedFilters.activities && systemDefinedFilters.activities.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for activities filter.
  });
}

// Apply Notes Filter
if (systemDefinedFilters.notes && systemDefinedFilters.notes.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for notes filter.
  });
}

// Apply Campaigns Filter
if (systemDefinedFilters.campaigns && systemDefinedFilters.campaigns.enabled) {
  updatedaccounts = updatedaccounts.filter((account) => {
    // Add logic for campaigns filter.
  });
}

    // Apply field filters
    if (selectedFieldFilters.length > 0) {
      updatedaccounts = updatedaccounts.filter((accounts) => {
        return selectedFieldFilters.every((filter) => {
          const field = filter.toLowerCase().replace(/\s+/g, '');
          return fieldSearchTerms[filter] ? accounts[field]?.includes(fieldSearchTerms[filter]) : true;
        });
      });
    }
  
    setFilteredAccounts(updatedaccounts);
  }, [selectedSystemFilters, selectedFieldFilters, accounts,fieldSearchTerms]);
  return (
    <Box>
    <Helmet>
      <title>Accounts - Contacttab.com</title>
    </Helmet>
      {/* Header Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, backgroundColor: 'white', color: 'black', marginTop: "5.2%", padding: '5px'}}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" style={{color: 'white', background: 'black', borderRadius: '5px'}}>
      {/* <InputLabel id="lead-select-label"  style={{color: 'white'}} >Public Views</InputLabel> */}
      <Select
        labelId="lead-select-label"
        value={selectedView}
        onChange={handleChange}
        label="Public Views"
        style={{color: 'white'}}
      >
        <MenuItem value="All Accounts">All Accounts</MenuItem>
        <MenuItem value="Today's Accountfd">Today's Accountfd</MenuItem>
        <MenuItem value="New This Week">New This Week</MenuItem>
        <MenuItem value="New Last Week">New Last Week</MenuItem>
        <MenuItem value="Recently Created Accounts">Recently Created Accounts</MenuItem>
        <MenuItem value="Recently Modified Accouts">Recently Modified Accouts</MenuItem>
        <MenuItem value="All Locked Accounts">All Locked Accounts</MenuItem>
        <MenuItem value="My Accounts">My Accounts</MenuItem>
        <MenuItem value="Unread Accounts">Unread Accounts</MenuItem>
      </Select>
    </FormControl>
        <Box>
        <Button variant="contained" onClick={handleViewMenuOpen} startIcon={<ViewListIcon />}>List View</Button> {/* Added List View Button */}
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => navigate('/create-account')}>Create Account</Button>
          <Button variant="contained" onClick={handleMenuOpen}>Actions</Button>
          <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Mass Delete</MenuItem>
            <MenuItem onClick={handleMenuClose}>Mass Update</MenuItem>
            <MenuItem onClick={handleMenuClose}>Manage Tags</MenuItem>
            <MenuItem onClick={handleMenuClose}>Drafts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Deduplicate Accounts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Create Client Script</MenuItem>
            <MenuItem onClick={handleMenuClose}>Export Accounts</MenuItem>
            <MenuItem onClick={handleMenuClose}>Zoho Sheet View</MenuItem>
            <MenuItem onClick={handleMenuClose}>Print View</MenuItem>
          </Menu>
          <Menu anchorEl={viewAnchorEl} open={viewMenuOpen} onClose={handleViewMenuClose}> {/* Added List View Menu */}
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TocIcon /></span> List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewKanbanIcon /></span> Kanban View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><ViewListIcon /></span> Custom List View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><GridViewIcon /></span> Tile View</MenuItem>
            <MenuItem onClick={handleViewMenuClose}><span style={{marginRight: '10px', marginLeft: '-5px'}}><TableViewIcon /></span> Table View</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container columnGap={5}> 
     
        <Grid item xs={2}>
      <Box
        sx={{
          p: 2,
          borderRight: "1px solid #e0e0e0",
          height: "76vh",
          background: "black",
          overflowY: "auto",
          marginTop: "20px"
        }}
      >
        <Typography variant="h6" color="white" style={{fontSize: "16px"}}>Filter Accounts by</Typography>
        <TextField
          variant="outlined"
          placeholder="Search"
          size="small"
          fullWidth
          sx={{ mb: 2 }}
          value={searchTerm}
          onChange={handleSearchChange}
        />

        {/* <Typography
          variant="body2"
          color="white"
          sx={{ mb: 1, cursor: "pointer", fontSize: "16px"}}
          onClick={toggleSystemFilters}
        >
        System Defined Filters <IconButton
            sx={{
              color: "white",
              transform: systemFiltersVisible ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          >
            <ArrowRightIcon />
          </IconButton>
         
        </Typography>
        {systemFiltersVisible && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredSystemDefinedFilters.map((filter) => (
              <FormControlLabel
                key={filter}
                control={
                <Checkbox 
                name={filter}
                onChange={handleSystemFilterChange}
                checked={selectedSystemFilters.includes(filter)} 
                />}
                label={filter}
                sx={{ color: "white" }}
              />
            ))}
          </Box>
        )} */}
<Typography
  variant="body2"
  color="white"
  sx={{ mb: 1, cursor: "pointer", fontSize: "16px" }}
  onClick={toggleSystemFilters}
>
  System Defined Filters{" "}
  <IconButton
    sx={{
      color: "white",
      transform: systemFiltersVisible ? "rotate(90deg)" : "rotate(0deg)",
      transition: "transform 0.3s",
    }}
  >
    <ArrowRightIcon />
  </IconButton>
</Typography>
{systemFiltersVisible && (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    {/* Touched Records */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.touchedRecords.enabled}
          onChange={() =>
            handleSystemFilterChange(
              "touchedRecords",
              "enabled",
              !systemDefinedFilters.touchedRecords.enabled
            )
          }
        />
      }
      label="Touched Records"
    />
    {systemDefinedFilters.touchedRecords.enabled && (
      <>
        <Select
          value={systemDefinedFilters.touchedRecords.by}
          onChange={(event) =>
            handleSystemFilterChange(
              "touchedRecords",
              "by",
              event.target.value
            )
          }
        >
          <MenuItem value="User & System">User & System</MenuItem>
          <MenuItem value="User Only">User Only</MenuItem>
          <MenuItem value="System Only">System Only</MenuItem>
        </Select>
        <Select
          value={systemDefinedFilters.touchedRecords.timeRange}
          onChange={(event) =>
            handleSystemFilterChange(
              "touchedRecords",
              "timeRange",
              event.target.value
            )
          }
        >
          <MenuItem value="2 days">in the last 2 days</MenuItem>
          <MenuItem value="7 days">in the last 7 days</MenuItem>
          <MenuItem value="30 days">in the last 30 days</MenuItem>
        </Select>
      </>
    )}

    {/* Repeat the similar structure for other filters */}
    {/* Untouched Records */}
    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.untouchedRecords.enabled}
          onChange={() =>
            handleSystemFilterChange(
              "untouchedRecords",
              "enabled",
              !systemDefinedFilters.untouchedRecords.enabled
            )
          }
        />
      }
      label="Untouched Records"
    />
    {systemDefinedFilters.untouchedRecords.enabled && (
      <>
        <Select
          value={systemDefinedFilters.untouchedRecords.by}
          onChange={(event) =>
            handleSystemFilterChange(
              "untouchedRecords",
              "by",
              event.target.value
            )
          }
        >
          <MenuItem value="User & System">User & System</MenuItem>
          <MenuItem value="User Only">User Only</MenuItem>
          <MenuItem value="System Only">System Only</MenuItem>
        </Select>
        <Select
          value={systemDefinedFilters.untouchedRecords.timeRange}
          onChange={(event) =>
            handleSystemFilterChange(
              "untouchedRecords",
              "timeRange",
              event.target.value
            )
          }
        >
          <MenuItem value="2 days">in the last 2 days</MenuItem>
          <MenuItem value="7 days">in the last 7 days</MenuItem>
          <MenuItem value="30 days">in the last 30 days</MenuItem>
        </Select>
      </>
    )}

    <FormControlLabel
      control={
        <Checkbox
          checked={systemDefinedFilters.recordAction.enabled}
          onChange={() =>
            handleSystemFilterChange(
              "recordAction",
              "enabled",
              !systemDefinedFilters.recordAction.enabled
            )
          }
        />
      }
      label="Record Action"
    />
    {systemDefinedFilters.recordAction.enabled && (
      <>
        <Select
          value={systemDefinedFilters.recordAction.actionType}
          onChange={(event) =>
            handleSystemFilterChange(
              "recordAction",
              "actionType",
              event.target.value
            )
          }
        >
          <MenuItem value="Modified">Modified</MenuItem>
          <MenuItem value="Created">Created</MenuItem>
        </Select>
        <Select
          value={systemDefinedFilters.recordAction.by}
          onChange={(event) =>
            handleSystemFilterChange("recordAction", "by", event.target.value)
          }
        >
          <MenuItem value="User & System">User & System</MenuItem>
          <MenuItem value="User Only">User Only</MenuItem>
          <MenuItem value="System Only">System Only</MenuItem>
        </Select>
        <Select
          value={systemDefinedFilters.recordAction.timeRange}
          onChange={(event) =>
            handleSystemFilterChange(
              "recordAction",
              "timeRange",
              event.target.value
            )
          }
        >
          <MenuItem value="2 days">in the last 2 days</MenuItem>
          <MenuItem value="7 days">in the last 7 days</MenuItem>
          <MenuItem value="30 days">in the last 30 days</MenuItem>
        </Select>
      </>
      )}
{/* Deals */}
<FormControlLabel
control={
  <Checkbox
    checked={systemDefinedFilters.deals.enabled}
    onChange={() =>
      handleSystemFilterChange(
        "deals",
        "enabled",
        !systemDefinedFilters.deals.enabled
      )
    }
  />
}
label="Deals"
/>
{systemDefinedFilters.deals.enabled && (
<>
  <FormControl>
    <FormLabel>Deals</FormLabel>
    <RadioGroup
      value={systemDefinedFilters.deals.type}
      onChange={(event) =>
        handleSystemFilterChange("deals", "type", event.target.value)
      }
    >
      <FormControlLabel
        value="withOpenDeal"
        control={<Radio />}
        label="With Open Deal"
      />
      <FormControlLabel
        value="withoutOpenDeal"
        control={<Radio />}
        label="Without Open Deal"
      />
      <FormControlLabel
        value="withoutAnyDeal"
        control={<Radio />}
        label="Without Any Deal"
      />
    </RadioGroup>
  </FormControl>
  <Select
    value={systemDefinedFilters.deals.timeRange}
    onChange={(event) =>
      handleSystemFilterChange("deals", "timeRange", event.target.value)
    }
  >
    <MenuItem value="1 month">in the last 1 month</MenuItem>
    <MenuItem value="3 months">in the last 3 months</MenuItem>
    <MenuItem value="6 months">in the last 6 months</MenuItem>
  </Select>
</>
)}

{/* Deal Amount */}
<FormControlLabel
control={
  <Checkbox
    checked={systemDefinedFilters.dealAmount.enabled}
    onChange={() =>
      handleSystemFilterChange(
        "dealAmount",
        "enabled",
        !systemDefinedFilters.dealAmount.enabled
      )
    }
  />
}
label="Deal Amount"
/>
{systemDefinedFilters.dealAmount.enabled && (
<>
  <Select
    value={systemDefinedFilters.dealAmount.operator}
    onChange={(event) =>
      handleSystemFilterChange(
        "dealAmount",
        "operator",
        event.target.value
      )
    }
  >
    <MenuItem value=">=">=</MenuItem>
    <MenuItem value="<=">{`<=`}</MenuItem>
  </Select>
  <TextField
    value={systemDefinedFilters.dealAmount.value}
    onChange={(event) =>
      handleSystemFilterChange("dealAmount", "value", event.target.value)
    }
    label="Rs."
  />
</>
)}

{/* Deal Stage */}
<FormControlLabel
control={
  <Checkbox
    checked={systemDefinedFilters.dealStage.enabled}
    onChange={() =>
      handleSystemFilterChange(
        "dealStage",
        "enabled",
        !systemDefinedFilters.dealStage.enabled
      )
    }
  />
}
label="Deal Stage"
/>
{systemDefinedFilters.dealStage.enabled && (
<Select
  value={systemDefinedFilters.dealStage.value}
  onChange={(event) =>
    handleSystemFilterChange("dealStage", "value", event.target.value)
  }
>
  <MenuItem value="None">None</MenuItem>
  <MenuItem value="In Progress">In Progress</MenuItem>
  <MenuItem value="Completed">Completed</MenuItem>
</Select>
)}

{/* Deal Owner */}
<FormControlLabel
control={
  <Checkbox
    checked={systemDefinedFilters.dealOwner.enabled}
    onChange={() =>
      handleSystemFilterChange(
        "dealOwner",
        "enabled",
        !systemDefinedFilters.dealOwner.enabled
      )
    }
  />
}
label="Deal Owner"
/>
{systemDefinedFilters.dealOwner.enabled && (
<Select
  value={systemDefinedFilters.dealOwner.value}
  onChange={(event) =>
    handleSystemFilterChange("dealOwner", "value", event.target.value)
  }
>
  {/* Populate with deal owners */}
</Select>
)}

{/* Deal Closing Date */}
<FormControlLabel
control={
  <Checkbox
    checked={systemDefinedFilters.dealClosingDate.enabled}
    onChange={() =>
      handleSystemFilterChange(
        "dealClosingDate",
        "enabled",
        !systemDefinedFilters.dealClosingDate.enabled
      )
    }
  />
}
label="Deal Closing Date"
/>
{systemDefinedFilters.dealClosingDate.enabled && (
<Select
  value={systemDefinedFilters.dealClosingDate.timeRange}
  onChange={(event) =>
    handleSystemFilterChange(
      "dealClosingDate",
      "timeRange",
      event.target.value
    )
  }
>
  <MenuItem value="2 days">in the last 2 days</MenuItem>
  <MenuItem value="7 days">in the last 7 days</MenuItem>
  <MenuItem value="30 days">in the last 30 days</MenuItem>
</Select>
)}

{/* Contacts */}
<FormControlLabel
control={
  <Checkbox
    checked={systemDefinedFilters.contacts.enabled}
    onChange={() =>
      handleSystemFilterChange(
        "contacts",
        "enabled",
        !systemDefinedFilters.contacts.enabled
      )
    }
  />
}
label="Contacts"
/>
{systemDefinedFilters.contacts.enabled && (
<FormControl>
  <FormLabel>Contacts</FormLabel>
  <RadioGroup
    value={systemDefinedFilters.contacts.type}
    onChange={(event) =>
      handleSystemFilterChange("contacts", "type", event.target.value)
    }
  >
    <FormControlLabel
      value="withContact"
      control={<Radio />}
      label="With Contact"
    />
    <FormControlLabel
      value="withoutAnyContact"
      control={<Radio />}
      label="Without Any Contact"
    />
  </RadioGroup>
</FormControl>
)}
    {/* Similarly, add other filters such as Related Records Action, Latest Email Status, Activities, Notes, Campaigns, etc. */}

  </Box>
)}

        <Typography
          variant="body2"
          color="white"
          sx={{ mt: 2, mb: 1, cursor: "pointer", fontSize: "16px" }}
          onClick={toggleFieldFilters}
        >
          Filter By Fields <IconButton
           sx={{
            color: "white",
            transform: fieldFiltersVisible ? 'rhandleFieldSearchChangeotate(90deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s'
          }}
          >
            <ArrowRightIcon />
          </IconButton>
        </Typography>
           {fieldFiltersVisible && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
            {filteredFieldFilters.map((filter) => (
              <Box key={filter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFieldFilters.includes(filter)}
                      onChange={handleFieldFilterChange}
                      name={filter}
                    />
                  }
                  label={filter}
                />
                {selectedFieldFilters.includes(filter) && (
                  <TextField
                    label={`Search ${filter}`}
                    value={fieldSearchTerms[filter] || ""}
                    onChange={(event) => handleFieldSearchChange(event, filter)}
                  />
                )}
              </Box>
            ))}
            </Box>
            )}
      </Box>
    </Grid>
            <Grid item xs={9.5}>
          <Paper elevation={3} style={{ padding: '20px', height: '76vh', marginTop: '20px', color: 'black' }} className="listViewStyle">
            <Typography variant="h6" sx={{ mb: 2 }} style={{color: 'white'}}>Total Records: {filteredAccounts.length}</Typography>
            <DataGrid
              columns={columns}
              rows={filteredAccounts}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row.accountid}
            />
            
            
            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>Are you sure you want to delete this Account?</DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  No
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllAccount;
